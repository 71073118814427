export const SCREENS = {
  NotFound: {
    name: "not-found",
    title: "NotFound",
  },
  PROFILE: {
    name: "profile",
    title: "Profile",
  },
  COMMUNITY_FEED: {
    name: "community-feed",
    title: "Feed",
  },
  COMMUNITIES: {
    name: "communities",
    title: "Communities",
  },
  EXPLORE_COMMUNITIES: {
    name: "communities-explore",
    title: "Explore Communities",
  },
  COMMUNITY: {
    name: "community",
    title: "Community",
  },
  CREATE_COMMUNITY: {
    name: "create-community",
    title: "Create Community",
  },
  COMMUNITY_SETTINGS: {
    name: "community-settings",
    title: "Community Settings",
  },
  HOME: {
    name: "home",
    title: "Home",
  },
  CREATE_COMMUNITY_POST: {
    name: "create-community-post",
    title: "Community Post",
  },
  MY_COMMUNITY_LIST: {
    name: "my-communities",
    title: "Communities",
  },
  MEDIA_GALLERY: {
    name: "media-gallery",
    title: "Media",
  },
  POST_COMMENTS: {
    name: "comments",
    title: "Comments",
  },
  ACCOUNT: {
    name: "account",
    title: "Account",
  },
  USER_PROFILE: {
    name: "profile",
    title: "My Profile",
  },
  LOGIN: {
    name: "login",
    title: "Login",
  },
  REGISTER: {
    name: "register",
    title: "Register",
  },
  PASSWORD_RESET: {
    name: "password-reset",
    title: "Password Reset",
  },
  MY_POSTS: {
    name: "my-posts",
    title: "My Posts",
  },
  REPLIES: {
    name: "replies",
    title: "Replies",
  },
  WEB_VIEW: {
    name: "web-view",
    title: "Website",
  },
  SEARCH_COMMUNITIES: {
    name: "search-communities",
    title: "Search Communities",
  },
  NOTIFICATIONS: {
    name: "notifications",
    title: "Notifications",
  },
  EDIT_COMMUNITY: {
    name: "edit-community",
    title: "Edit Community",
  },
  COMMUNITY_MEMBERS: {
    name: "community-members",
    title: "Members",
  },
  GLOBAL_COMMUNITIES: {
    name: "GlobalCommunities",
    title: "Global",
  },
  COUNTRY_COMMUNITIES: {
    name: "CountryCommunities",
    title: "Country",
  },
  CITY_COMMUNITIES: {
    name: "CityCommunities",
    title: "City ",
  },
  LOCAL_COMMUNITIES: {
    name: "LocalCommunities",
    title: "Local",
  },
} as const;

export default SCREENS;
