import { Center, FlatList, Spinner } from "native-base";
import { useInfiniteQuery } from "@tanstack/react-query";
import { NavigationProp, ParamListBase } from "@react-navigation/native";
import { getMyCommunities } from "../../api/community";
// import { useRefetchOnFocus } from "hooks/useRefetchOnFocus";
import * as Sentry from "sentry-expo";
import CommunityItem from "components/CommunityItem/CommunityItem";
import SCREENS from "constants/Screen";
import EmptyList from "components/EmptyList/EmptyList";
import Colors from "constants/Colors";
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";
import { ICommunityMember } from "types/index";

interface MyCommunityListProps {
  navigation: NavigationProp<ParamListBase>;
}

export const MyCommunityList = ({ navigation }: MyCommunityListProps) => {
  const {
    isPending,
    data,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    error,
  } = useInfiniteQuery({
    queryKey: ["myCommunityList"],
    queryFn: async ({ pageParam = 1 }) => {
      const { data } = await getMyCommunities({ pageParam });
      return data;
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      if (lastPage.page + 1 > lastPage.totalPages) {
        return null;
      }

      return lastPage.page + 1;
    },
  });

  if (error) {
    Sentry.Native.captureException(error);
  }

  // useRefetchOnFocus(refetch);

  const loadMore = () => {
    if (hasNextPage) {
      fetchNextPage();
    }
  };

  const renderCommunity = ({ item }: { item: ICommunityMember }) => {
    return (
      <CommunityItem
        community={item.community}
        onPress={() =>
          navigation.navigate(SCREENS.COMMUNITY.name, {
            communityId: item.community.id,
          })
        }
      />
    );
  };

  if (isPending) {
    return (
      <Center
        flex={1}
        _dark={{
          bg: Colors.dark.background,
        }}
        _light={{
          bg: Colors.light.background,
        }}
      >
        <Spinner />
      </Center>
    );
  }

  return (
    <ErrorBoundary>
      <FlatList
        flex={1}
        p={4}
        data={data?.pages.flatMap((page) => page.results)}
        renderItem={renderCommunity}
        keyExtractor={(item, index) => item.id || index.toString()}
        ListEmptyComponent={<EmptyList text="No communities" />}
        onEndReachedThreshold={0.2}
        ListFooterComponent={isFetchingNextPage ? <Spinner /> : null}
        onEndReached={loadMore}
      />
    </ErrorBoundary>
  );
};

export default MyCommunityList;
