import React, { useState } from "react";
import { Formik } from "formik";
import { useMutation } from "@tanstack/react-query";
import { NavigationProp, ParamListBase } from "@react-navigation/native";
import * as Yup from "yup";
import * as Sentry from "sentry-expo";
import Toast from "react-native-toast-message";

import {
  Button,
  Heading,
  VStack,
  Text,
  Spinner,
  ScrollView,
  Box,
  FormControl,
  WarningOutlineIcon,
  Input,
  useColorModeValue,
} from "native-base";

import Colors from "constants/Colors";
import SCREENS from "constants/Screen";
import { passwordReset } from "../api/auth";
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";

export interface PasswordResetScreenProps {
  navigation: NavigationProp<ParamListBase>;
}

const LoginSchema = Yup.object({
  email: Yup.string().email("Invalid email address").required("Required"),
});

const PasswordResetScreen = ({ navigation }: PasswordResetScreenProps) => {
  const [, setError] = useState("");
  const bg = useColorModeValue(Colors.light.background, Colors.dark.background);

  // Mutations
  const mutation = useMutation({
    mutationFn: passwordReset,
    onSuccess: async () => {
      navigation.navigate(SCREENS.LOGIN.name);
      Toast.show({
        type: "success",
        text1: "Check your email for reset link",
      });
    },
    onError: (error) => {
      Sentry.Native.captureException(error);
      switch (error.code) {
        case "auth/wrong-password" || "auth/invalid-email":
          setError("You have entered an invalid email or password");
          break;
        case "auth/user-disabled":
          setError("Your account is suspended. Contact support@closefeed.com");
          break;
        case "auth/user-not-found":
          setError("No user found with this email");
          break;
        default:
          setError(error.message || "Something went wrong");
      }
    },
  });

  return (
    <ErrorBoundary>
      <Box flex={1} bg={bg}>
        <ScrollView p={5}>
          <VStack space={1} mb={10}>
            <Heading size="xl">Forgot Password?</Heading>
            <Text fontSize="md" color="dark.400">
              An email will be sent to reset your password
            </Text>
          </VStack>

          <Formik
            initialValues={{ email: "" }}
            onSubmit={(values) => mutation.mutate(values.email)}
            validationSchema={LoginSchema}
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              values,
              errors,
              touched,
              isValid,
              dirty,
            }) => (
              <VStack space={3}>
                <FormControl
                  isInvalid={errors.email && touched.email ? true : false}
                >
                  <FormControl.Label>Email</FormControl.Label>
                  <Input
                    size="xl"
                    placeholder="email@example.com"
                    autoCorrect={false}
                    keyboardType="email-address"
                    onChangeText={handleChange("email")}
                    onBlur={handleBlur("email")}
                    value={values.email}
                  />
                  {errors.email && touched.email ? (
                    <FormControl.ErrorMessage
                      leftIcon={<WarningOutlineIcon size="xs" />}
                    >
                      {errors.email && touched.email ? errors.email : ""}
                    </FormControl.ErrorMessage>
                  ) : null}
                </FormControl>

                <Box>
                  {mutation.isPending ? (
                    <Spinner color="muted.500" />
                  ) : (
                    <VStack space={3}>
                      <Button
                        size="lg"
                        isDisabled={!(isValid && dirty)}
                        onPress={() => handleSubmit()}
                      >
                        Reset Password
                      </Button>
                      <Button
                        size="lg"
                        variant="ghost"
                        onPress={() => navigation.navigate(SCREENS.LOGIN.name)}
                      >
                        Login
                      </Button>
                    </VStack>
                  )}
                </Box>
              </VStack>
            )}
          </Formik>
        </ScrollView>
      </Box>
    </ErrorBoundary>
  );
};

export default PasswordResetScreen;
