import React, { useState } from "react";
import { Formik } from "formik";
import { useMutation } from "@tanstack/react-query";
import { NavigationProp, ParamListBase } from "@react-navigation/native";
import * as Yup from "yup";
import * as Sentry from "sentry-expo";

import Colors from "constants/Colors";
import SCREENS from "constants/Screen";
import { postRegister } from "../api/auth";
import {
  VStack,
  ScrollView,
  Text,
  Box,
  useColorModeValue,
  Heading,
  FormControl,
  WarningOutlineIcon,
  Input,
  Button,
  Spinner,
} from "native-base";
import { AxiosError } from "axios";
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";

export interface RegisterScreenProps {
  navigation: NavigationProp<ParamListBase>;
}

const RegisterSchema = Yup.object({
  displayName: Yup.string()
    .max(10, "Must be 10 characters or less")
    .required("Required"),

  email: Yup.string().email("Invalid email address").required("Required"),
  password: Yup.string()
    .required("Required")
    .min(6, "Must be 6 characters or more")
    .test(
      "password-contains-letter-and-number",
      "Password must contain at least 1 letter and 1 number",
      (value) => {
        const letterRegex = /[a-zA-Z]/;
        const numberRegex = /[0-9]/;
        return letterRegex.test(value || "") && numberRegex.test(value || "");
      }
    ),
});

const RegisterScreen = ({ navigation }: RegisterScreenProps) => {
  const [error, setError] = useState("");

  const bg = useColorModeValue(Colors.light.background, Colors.dark.background);

  // Mutations
  const mutation = useMutation({
    mutationFn: postRegister,
    onSuccess: async () => {},
    onError: async (error: AxiosError) => {
      Sentry.Native.captureException(error);
      setError(error.response?.data.message || "Something went wrong");
    },
  });

  const handleWebviewPress = async (uri: string) => {
    navigation.navigate(SCREENS.WEB_VIEW.name, {
      uri,
    });
  };

  return (
    <ErrorBoundary>
      <Box flex={1} bg={bg}>
        <ScrollView p={5}>
          <VStack space={1} mb={10}>
            <Heading size="xl">Register</Heading>
            <Text fontSize="md" color="dark.400">
              Use your email to create an account
            </Text>
          </VStack>

          <Formik
            initialValues={{ email: "", password: "", displayName: "" }}
            onSubmit={async (values) => {
              try {
                await mutation.mutateAsync(values);
              } catch (error) {
                Sentry.Native.captureException(error);
              }
            }}
            validationSchema={RegisterSchema}
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              values,
              errors,
              touched,
              isValid,
              dirty,
            }) => (
              <VStack space={3}>
                <FormControl
                  isInvalid={
                    errors.displayName && touched.displayName ? true : false
                  }
                >
                  <FormControl.Label>Display Name</FormControl.Label>
                  <Input
                    size="xl"
                    placeholder="David Alex"
                    autoCorrect={false}
                    onChangeText={handleChange("displayName")}
                    onBlur={handleBlur("displayName")}
                    value={values.displayName}
                  />
                  <FormControl.HelperText>
                    We recommend not using your real name
                  </FormControl.HelperText>
                  {errors.displayName && touched.displayName ? (
                    <FormControl.ErrorMessage
                      leftIcon={<WarningOutlineIcon size="xs" />}
                    >
                      {errors.displayName && touched.displayName
                        ? errors.displayName
                        : ""}
                    </FormControl.ErrorMessage>
                  ) : null}
                </FormControl>

                <FormControl
                  isInvalid={errors.email && touched.email ? true : false}
                >
                  <FormControl.Label>Email</FormControl.Label>
                  <Input
                    size="xl"
                    placeholder="email@example.com"
                    autoCorrect={false}
                    keyboardType="email-address"
                    onChangeText={handleChange("email")}
                    onBlur={handleBlur("email")}
                    value={values.email}
                  />
                  {errors.email && touched.email ? (
                    <FormControl.ErrorMessage
                      leftIcon={<WarningOutlineIcon size="xs" />}
                    >
                      {errors.email && touched.email ? errors.email : ""}
                    </FormControl.ErrorMessage>
                  ) : null}
                </FormControl>

                <FormControl
                  isInvalid={errors.password && touched.password ? true : false}
                >
                  <FormControl.Label>Password</FormControl.Label>
                  <Input
                    size="xl"
                    placeholder="**********"
                    secureTextEntry
                    onChangeText={handleChange("password")}
                    onBlur={handleBlur("password")}
                    value={values.password}
                  />
                  {errors.password && touched.password ? (
                    <FormControl.ErrorMessage
                      leftIcon={<WarningOutlineIcon size="xs" />}
                    >
                      {errors.password && touched.password
                        ? errors.password
                        : ""}
                    </FormControl.ErrorMessage>
                  ) : null}
                </FormControl>

                <Text>
                  By signing up, you agree to the{" "}
                  <Text
                    onPress={() =>
                      handleWebviewPress("https://www.closefeed.com/terms")
                    }
                    color="danger.500"
                  >
                    Terms of Service
                  </Text>{" "}
                  and{" "}
                  <Text
                    onPress={() =>
                      handleWebviewPress("https://www.closefeed.com/privacy")
                    }
                    color="danger.500"
                  >
                    Privacy Policy.
                  </Text>
                </Text>

                {error ? (
                  <Text style={{ color: Colors.light.danger }}>{error}</Text>
                ) : null}
                <Box>
                  {mutation.isPending ? (
                    <Spinner color="muted.500" />
                  ) : (
                    <VStack space={3}>
                      <Button
                        size="lg"
                        isDisabled={!(isValid && dirty)}
                        onPress={() => handleSubmit()}
                      >
                        Get Started
                      </Button>
                      <Button
                        size="lg"
                        variant="ghost"
                        onPress={() => navigation.navigate(SCREENS.LOGIN.name)}
                      >
                        Login
                      </Button>
                    </VStack>
                  )}
                </Box>
              </VStack>
            )}
          </Formik>
        </ScrollView>
      </Box>
    </ErrorBoundary>
  );
};

export default RegisterScreen;
