import { Box, Heading, Row, Text, Image } from "native-base";
import { ICommunity } from "types/index";
import { TouchableOpacity } from "react-native";
import Colors from "constants/Colors";

interface CommunityItemProps {
  community: ICommunity;
  onPress: () => void;
}

const CommunityItem = ({ community, onPress }: CommunityItemProps) => {
  // Define a list of emojis to display based on the members count
  const emojis = ["😄", "🥸", "🧑‍🎄", "😎"];

  // Generate emoji elements based on the members count
  const generateEmojis = (count: number) => {
    // Limit the number of emojis to 4
    const emojiCount = Math.min(count, 4);
    return Array.from({ length: emojiCount }, (_, index) => (
      <Box
        key={index}
        h={8}
        w={8}
        rounded="full"
        alignItems="center"
        justifyContent="center"
        borderWidth={2}
        borderColor="gray.400"
        bg="white"
        ml={index > 0 ? -3 : 0}
        _dark={{
          bg: Colors.dark.background,
        }}
      >
        {emojis[index]}
      </Box>
    ));
  };

  return (
    <TouchableOpacity onPress={onPress}>
      <Row alignItems="center" space={4} mb={4}>
        <Image
          size="sm"
          source={{ uri: community.profileImage }}
          alt={community.name}
          rounded="sm"
        />
        <Box>
          <Heading
            size="sm"
            color="gray.800"
            _dark={{
              color: "gray.100",
            }}
          >
            {community.name}
          </Heading>
          <Text
            _light={{
              color: "gray.600",
            }}
            _dark={{
              color: "gray.400",
            }}
          >
            {community.description}
          </Text>

          <Row alignItems="center" space={2}>
            <Text>
              <Text bold>{community.membersCount}</Text>
              <Text color="gray.500">
                {community.membersCount > 1 ? " members" : " member"}
              </Text>
            </Text>
            <Row space={-2}>{generateEmojis(community.membersCount)}</Row>
          </Row>
        </Box>
      </Row>
    </TouchableOpacity>
  );
};

export default CommunityItem;
