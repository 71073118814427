import request from "../utils/request";

export const getUserNotifications = async ({
  pageParam,
}: {
  pageParam: number;
}) => {
  return request({
    method: "get",
    url: "/notifications/me",
    params: {
      sortBy: "createdAt:desc",
      limit: 20,
      page: pageParam,
    },
  });
};
