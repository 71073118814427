import React from "react";
import { TouchableOpacity } from "react-native";
import { Box, Text, Row, Column } from "native-base";
import relativeTime from "dayjs/plugin/relativeTime";
import { Feather } from "@expo/vector-icons";
import dayjs from "dayjs";
dayjs.extend(relativeTime);

import SCREENS from "constants/Screen";
import * as RootNavigation from "../../navigation/RootNavigation";
import { IPost } from "types/index";
import Votes from "../Votes/Votes";
import CommentCount from "../CommentCount/CommentCount";
import Colors from "constants/Colors";
import { usePostActions } from "hooks/community/usePostActions";
import PostContent from "components/PostContent/PostContent";

interface PostProps {
  data: IPost;
  queryName: string;
  showUser?: boolean;
  truncate?: boolean;
  onDownVotePress: () => void;
  onUpVotePress: () => void;
  disabled?: boolean;
}

const Post = ({
  data,
  queryName,
  truncate = false,
  onDownVotePress,
  onUpVotePress,
  disabled = false,
}: PostProps) => {
  const { userSnapshot } = data;
  const { onOpenActionSheet } = usePostActions(queryName);

  const navigateToCommentsScreen = () => {
    RootNavigation.navigate(SCREENS.POST_COMMENTS.name, {
      postId: data.id,
    });
  };

  if (data.deleted) {
    return (
      <Box p={4}>
        <Text>Post deleted</Text>
      </Box>
    );
  }

  return (
    <Column space={2} py={2} px={4}>
      <PostContent
        disabled={disabled}
        truncate={truncate}
        onPress={navigateToCommentsScreen}
        data={data}
        actions={
          <Row justifyContent="space-between" alignItems="center" flex={1}>
            <Votes
              count={data.upVotes - data.downVotes || 0}
              onDownVotePress={onDownVotePress}
              onUpVotePress={onUpVotePress}
              userVote={data.userVote}
            />
            <CommentCount
              onPress={navigateToCommentsScreen}
              count={data.numberOfComments}
            />
            {userSnapshot ? (
              <TouchableOpacity
                onPress={() =>
                  onOpenActionSheet({
                    userId: userSnapshot._id,
                    displayName: userSnapshot.displayName,
                    postId: data.id,
                  })
                }
              >
                <Feather
                  name="flag"
                  size={18}
                  color={Colors.light.secondary_text}
                />
              </TouchableOpacity>
            ) : null}
          </Row>
        }
      />
    </Column>
  );
};

export default Post;
