import React, { useCallback, useRef } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Toast from "react-native-toast-message";
import { NativeBaseProvider, useColorMode } from "native-base";
import { ActionSheetProvider } from "@expo/react-native-action-sheet";

import useCachedResources from "./hooks/useCachedResources";
import Navigation from "./navigation";
import { UserProvider } from "./context/UserContext";
import { colorModeManager, theme } from "./config/nativebaseConfig";
import "./config/sentry";

export default function App() {
  const { colorMode } = useColorMode();
  const isLoadingComplete = useCachedResources();

  const queryClient = useRef(new QueryClient()).current;

  const toastRef = useCallback((ref: unknown) => Toast.setRef(ref), []);

  if (!isLoadingComplete || !queryClient) {
    return null;
  } else {
    return (
      <QueryClientProvider client={queryClient}>
        <UserProvider>
          <ActionSheetProvider>
            <NativeBaseProvider
              theme={theme}
              colorModeManager={colorModeManager}
            >
              <Navigation colorScheme={colorMode} />
              <Toast ref={toastRef} />
            </NativeBaseProvider>
          </ActionSheetProvider>
        </UserProvider>
      </QueryClientProvider>
    );
  }
}
