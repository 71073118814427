import { useQuery } from "@tanstack/react-query";
import { getCommunity, isMember } from "../../../api/community";

export const MEMBERSHIP_STATUS_QUERY_NAME = "membershipStatus";
export const COMMUNITY_DETAIL_QUERY_NAME = "communityDetail";

export const useCommunityData = (communityId: string) => {
  const communityDetail = useQuery({
    queryKey: [COMMUNITY_DETAIL_QUERY_NAME, communityId],
    queryFn: () => getCommunity(communityId),
  });

  const membershipStatus = useQuery({
    queryKey: [MEMBERSHIP_STATUS_QUERY_NAME, communityId],
    queryFn: () => isMember(communityId),
  });

  return {
    communityDetail,
    membershipStatus,
  };
};
