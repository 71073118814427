import { useMutation, useQueryClient } from "@tanstack/react-query";
import * as Sentry from "sentry-expo";
import Toast from "react-native-toast-message";
import * as Haptics from "expo-haptics";
import { AxiosError } from "axios";

import { createPostVote } from "../api/post";
import { IPost } from "types/index";

const useVoteMutation = (postId: string, queryName: string) => {
  const queryClient = useQueryClient();

  const handleVote = async (vote: -1 | 1) => {
    Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);

    const { data } = await createPostVote(postId, vote);
    return data;
  };

  const mutation = useMutation({
    mutationFn: handleVote,
    onMutate: async (newVote: number) => {
      await queryClient.cancelQueries({ queryKey: [queryName, postId] });
      await queryClient.cancelQueries({ queryKey: [queryName] });

      // Backup of the previous state
      const previousPost = queryClient.getQueryData([queryName, postId]);

      // Optimistically update the single post

      queryClient.setQueryData([queryName, postId], (old: IPost) => {
        return {
          data: {
            ...old.data,
            userVote: newVote,
            upVotes: newVote === 1 ? old.upVotes + 1 : old.upVotes,
            downVotes: newVote === -1 ? old.downVotes + 1 : old.downVotes,
            totalVotes:
              newVote === 1 ? old?.totalVotes + 1 : old?.totalVotes - 1,
          },
        };
      });

      return { previousPost };
    },
    onError: (error: AxiosError, newVote, context) => {
      queryClient.setQueryData([queryName, postId], context?.previousPost);
      Sentry.Native.captureException(error);
      Toast.show({
        type: "info",
        text1: "Error",
        text2: error.response?.data.message || "Something went wrong",
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [queryName, postId] });
      queryClient.invalidateQueries({ queryKey: [queryName] });
    },
  });

  return mutation;
};

export default useVoteMutation;
