import { Box, FlatList, Spinner, Image, Text } from "native-base";
import { TouchableOpacity } from "react-native";
import { NavigationProp, ParamListBase } from "@react-navigation/native";
import SCREENS from "constants/Screen";
import { ICommunityMember } from "types/index";
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";
import Separator from "components/Separator/Separator";

interface MyCommunitiesProps {
  navigation: NavigationProp<ParamListBase>;
  data?: ICommunityMember[];
  isPending?: boolean;
}

export const MyCommunities = ({
  navigation,
  data,
  isPending,
}: MyCommunitiesProps) => {
  const renderCommunity = ({ item }: { item: ICommunityMember }) => {
    return (
      <TouchableOpacity
        onPress={() =>
          navigation.navigate(SCREENS.COMMUNITY.name, {
            communityId: item.community.id,
          })
        }
        style={{ marginHorizontal: 5, alignItems: "center" }}
      >
        <Box alignItems="center" justifyContent="center">
          <Image
            source={{ uri: item.community.profileImage }}
            alt={item.community.name}
            size="xs"
            rounded="full"
            mb={2}
          />
          <Text maxW={70} textAlign="center" fontSize="xs" numberOfLines={2}>
            {item.community.name}
          </Text>
        </Box>
      </TouchableOpacity>
    );
  };

  const Header = () => (
    <TouchableOpacity
      onPress={() => navigation.navigate(SCREENS.CREATE_COMMUNITY.name)}
    >
      <Box alignItems="center" justifyContent="center" ml={4}>
        <Box
          borderColor="muted.300"
          h={45}
          w={45}
          rounded="full"
          borderWidth={1}
          alignItems="center"
          justifyContent="center"
          mb={1}
        >
          <Text>👥</Text>
        </Box>
        <Text maxW={70} textAlign="center" fontSize="xs">
          Create
        </Text>
      </Box>
    </TouchableOpacity>
  );

  const Footer = () => (
    <TouchableOpacity
      onPress={() => navigation.navigate(SCREENS.MY_COMMUNITY_LIST.name)}
    >
      <Box alignItems="center" justifyContent="center">
        <Box
          borderColor="muted.300"
          h={45}
          w={45}
          rounded="full"
          borderWidth={1}
          alignItems="center"
          justifyContent="center"
          mb={1}
        >
          <Text>⏩</Text>
        </Box>
        <Text maxW={70} textAlign="center" fontSize="xs">
          View all
        </Text>
      </Box>
    </TouchableOpacity>
  );

  return (
    <ErrorBoundary>
      <Box flex={1} mt={3}>
        {isPending ? (
          <Spinner />
        ) : (
          <Box>
            <FlatList
              horizontal
              flex={1}
              data={data}
              renderItem={renderCommunity}
              keyExtractor={(item, index) => item.id || index.toString()}
              ListFooterComponent={() => <Footer />}
              ListHeaderComponent={<Header />}
              mb={2}
            />
            <Separator />
          </Box>
        )}
      </Box>
    </ErrorBoundary>
  );
};

export default MyCommunities;
