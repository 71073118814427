import React from "react";
import { Box, Spinner } from "native-base";
import ImageViewer from "react-native-image-zoom-viewer";
import { IMedia } from "types/index";
import Colors from "constants/Colors";
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";

interface MediaGalleryProps {
  route: {
    params: {
      images: IMedia[];
      selectedIndex: number;
    };
  };
}

const MediaGallery = ({ route }: MediaGalleryProps) => {
  const { images, selectedIndex } = route.params;

  const imageSources = images.map((img) => ({ url: img.url }));

  return (
    <ErrorBoundary>
      <Box
        style={{
          flex: 1,
        }}
      >
        <ImageViewer
          index={selectedIndex}
          imageUrls={imageSources}
          enableSwipeDown={true}
          saveToLocalByLongPress={false}
          backgroundColor={Colors.dark.background}
          loadingRender={() => <Spinner />}
        />
      </Box>
    </ErrorBoundary>
  );
};

export default MediaGallery;
