import React, { useCallback, useMemo } from "react";
import { Box, Spinner, FlatList, Center } from "native-base";
import { useQuery } from "@tanstack/react-query";
import EmptyList from "components/EmptyList/EmptyList";
import CreateCommentForm from "components/CreateCommentForm/CreateCommentForm";
import Separator from "components/Separator/Separator";
import { IComment } from "types/index";
import { useComments } from "./hooks/useComments";
import { usePostComment } from "./hooks/usePostComment";
import { getPost } from "../../api/post";
import Post from "components/Post/Post";
import Colors from "constants/Colors";
import { ListRenderItem } from "react-native";
import useVoteMutation from "hooks/useVoteMutation";
import Comment from "components/Comment/Comment";
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";

interface CommentScreenProps {
  route: {
    params: {
      postId: string;
    };
  };
}
const POST_DETAIL_QUERY_KEY = "communityFeed";

const CommentScreen = ({ route }: CommentScreenProps) => {
  const { postId } = route.params;
  const voteMutation = useVoteMutation(postId, POST_DETAIL_QUERY_KEY);

  const postDetail = useQuery({
    queryKey: [POST_DETAIL_QUERY_KEY, postId],
    queryFn: () => getPost(postId),
  });

  const { isPending, data, fetchNextPage, isFetchingNextPage, hasNextPage } =
    useComments(postId);

  const loadMore = useCallback(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, fetchNextPage]);

  const createCommentMutation = usePostComment();

  const renderItem: ListRenderItem<IComment> = useCallback(({ item }) => {
    const renderComment = () => <Comment comment={item} />;

    return renderComment();
  }, []);

  const listHeaderComponent = useMemo(
    () => (
      <Box>
        <Post
          disabled
          data={postDetail.data?.data}
          queryName={POST_DETAIL_QUERY_KEY}
          showUser
          onDownVotePress={() => voteMutation.mutateAsync(-1)}
          onUpVotePress={() => voteMutation.mutateAsync(1)}
        />
      </Box>
    ),
    [postDetail.data?.data, voteMutation]
  );

  if (isPending || postDetail.isPending)
    return (
      <Center
        flex={1}
        _dark={{
          bg: Colors.dark.background,
        }}
        _light={{
          bg: Colors.light.background,
        }}
      >
        <Spinner />
      </Center>
    );

  return (
    <ErrorBoundary>
      <FlatList
        flex={1}
        data={data?.pages.flatMap((page) => page.results)}
        renderItem={renderItem}
        keyExtractor={(item, index) => item.id || index.toString()}
        ItemSeparatorComponent={Separator}
        ListEmptyComponent={<EmptyList text="No comments" />}
        ListHeaderComponent={listHeaderComponent}
        ListFooterComponent={isFetchingNextPage ? <Spinner /> : null}
        onEndReached={loadMore}
      />
      <CreateCommentForm
        submitComment={({ text, clearForm }) => {
          createCommentMutation.mutate({
            text,
            postId,
          });
          clearForm();
        }}
      />
    </ErrorBoundary>
  );
};

export default CommentScreen;
