import {
  Fab,
  Icon,
  Spinner,
  FlatList,
  useColorMode,
  Center,
  Box,
} from "native-base";
import {
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { ListRenderItem, RefreshControl, Share } from "react-native";
import { NavigationProp, ParamListBase } from "@react-navigation/native";
import * as Haptics from "expo-haptics";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import Toast from "react-native-toast-message";
import React, { useCallback, useEffect, useState } from "react";
import * as Sentry from "sentry-expo";
import { AxiosError, AxiosResponse } from "axios";
import Separator from "components/Separator/Separator";
import { createPostVote } from "../../api/post";
import { getCommunityPosts } from "../../api/community";
import { ICommunity, IPost, IResponse } from "types/index";
import SCREENS from "constants/Screen";
import EmptyList from "components/EmptyList/EmptyList";
import { joinCommunity, leaveCommunity } from "../../api/community";
import Colors from "constants/Colors";
import { usePostActions } from "hooks/community/usePostActions";
import {
  COMMUNITY_DETAIL_QUERY_NAME,
  MEMBERSHIP_STATUS_QUERY_NAME,
  useCommunityData,
} from "./hooks/useCommunityData";
import PostActions from "components/PostActions/PostActions";
import CommunityHeader from "./components/CommunityHeader/CommunityHeader";
import PostContent from "components/PostContent/PostContent";
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";

const COMMUNITY_POSTS_QUERY_NAME = "communityPosts";

interface CommunityProps {
  route: {
    params: {
      communityId: string;
    };
  };
  navigation: NavigationProp<ParamListBase>;
}

const CommunityScreen = ({ route, navigation }: CommunityProps) => {
  const [refreshing, setRefreshing] = useState(false);
  const communityId = route.params.communityId;
  const queryClient = useQueryClient();
  const { colorMode } = useColorMode();
  const colorScheme = colorMode || "light";

  const onPressShareCommunity = async () => {
    try {
      await Share.share({
        message: `Join ${communityDetailData.name} on CloseFeed!`,
        // url: `closefeed://community/${communityId}`,
        url: `https://www.closefeed.com/c/${communityId}`,
      });
    } catch (error) {
      alert(error.message);
    }
  };

  const { onOpenActionSheet, onOpenModActionSheet } =
    usePostActions(communityId);

  const { communityDetail, membershipStatus } = useCommunityData(communityId);

  const communityDetailData: ICommunity = communityDetail?.data?.data;
  const membershipStatusData: {
    isMember: boolean;
    isModerator: boolean;
  } = membershipStatus?.data?.data;

  useEffect(() => {
    if (communityDetailData?.name) {
      navigation.setOptions({ title: communityDetailData.name });
    }
  }, [communityDetailData]);

  const isMemberStatus: boolean = membershipStatusData?.isMember;
  const isModeratorStatus: boolean = membershipStatusData?.isModerator;

  const joinCommunityMutation = useMutation({
    mutationFn: () => joinCommunity(communityId),
    onSuccess: async () => {
      // Invalidate and refetch the isMember query whenever the joinCommunity is successful
      queryClient.invalidateQueries({
        queryKey: [MEMBERSHIP_STATUS_QUERY_NAME, communityId],
      });
      queryClient.invalidateQueries({
        queryKey: [COMMUNITY_DETAIL_QUERY_NAME],
      });
    },
    onError: (error: AxiosError) => {
      Toast.show({
        type: "error",
        text1: "Error",
        text2: error.response?.data.message || "Something went wrong",
      });
    },
  });

  const leaveCommunityMutation = useMutation({
    mutationFn: () => leaveCommunity(communityId),
    onSuccess: async () => {
      // Invalidate and refetch the isMember query whenever the leaveCommunity is successful
      queryClient.invalidateQueries({
        queryKey: [MEMBERSHIP_STATUS_QUERY_NAME, communityId],
      });
      queryClient.invalidateQueries({
        queryKey: [COMMUNITY_DETAIL_QUERY_NAME],
      });
    },
    onError: (error: AxiosError) => {
      Toast.show({
        type: "error",
        text1: "Error",
        text2: error.response?.data.message || "Something went wrong",
      });
    },
  });

  const handleJoin = async () => {
    await joinCommunityMutation.mutateAsync();
  };

  const handleLeave = async () => {
    await leaveCommunityMutation.mutateAsync();
  };

  const {
    isPending,
    data,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    refetch,
  } = useInfiniteQuery({
    queryKey: ["communityPosts", communityId],
    queryFn: async ({ pageParam = 1 }) => {
      const response: AxiosResponse<IResponse<IPost>> | void =
        await getCommunityPosts({
          pageParam,
          communityId,
        });
      const { data } = response || {
        data: {
          results: [],
          totalPages: 0,
          page: 0,
        },
      };
      return data;
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      if (lastPage.page + 1 > lastPage.totalPages) {
        return null;
      }

      return lastPage.page + 1;
    },
    refetchInterval: 1000 * 60 * 10, // 10 minutes,
  });

  const loadMore = () => {
    if (hasNextPage) {
      fetchNextPage();
    }
  };

  const navigateToCommentsScreen = (post: IPost) => {
    navigation.navigate(SCREENS.POST_COMMENTS.name, {
      postId: post.id,
    });
  };

  const handleVote = async ({
    postId,
    vote,
  }: {
    postId: string;
    vote: 1 | -1;
  }) => {
    Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
    await createPostVote(postId, vote);
  };

  const mutation = useMutation({
    mutationFn: handleVote,
    onError: (error: AxiosError) => {
      Sentry.Native.captureException(error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [COMMUNITY_POSTS_QUERY_NAME] });
    },
  });

  const renderItem: ListRenderItem<IPost> = useCallback(({ item }) => {
    return renderPost({ item });
  }, []);

  const renderPost = ({ item }: { item: IPost }) => {
    return (
      <Box px={4}>
        <PostContent
          truncate
          onPress={() => navigateToCommentsScreen(item)}
          data={item}
          actions={
            <PostActions
              post={item}
              onUpVote={() =>
                mutation.mutateAsync({ postId: item.id, vote: 1 })
              }
              onDownVote={() =>
                mutation.mutateAsync({ postId: item.id, vote: -1 })
              }
              onFlagPress={() =>
                onOpenActionSheet({
                  userId: item.userSnapshot._id,
                  displayName: item.userSnapshot.displayName,
                  postId: item.id,
                })
              }
              isModerator={isModeratorStatus}
              onModeratorAction={() =>
                onOpenModActionSheet({
                  userId: item.userSnapshot._id,
                  displayName: item.userSnapshot.displayName,
                  postId: item.id,
                  communityId: communityId,
                })
              }
              onCommentPress={() => navigateToCommentsScreen(item)}
            />
          }
        />
      </Box>
    );
  };

  if (isPending || communityDetail.isPending || membershipStatus.isPending)
    return (
      <Center
        flex={1}
        _dark={{
          bg: Colors.dark.background,
        }}
        _light={{
          bg: Colors.light.background,
        }}
      >
        <Spinner />
      </Center>
    );

  const handleRefresh = async () => {
    setRefreshing(true);
    await refetch();
    setRefreshing(false);
  };

  const showFab = isMemberStatus && communityDetailData.type === "public";

  return (
    <ErrorBoundary>
      <FlatList
        flex={1}
        data={data?.pages.flatMap((page) => page.results)}
        renderItem={renderItem}
        keyExtractor={(item) => item.id}
        ListHeaderComponent={
          <CommunityHeader
            onPressShare={onPressShareCommunity}
            onMemberCountPress={() =>
              navigation.navigate(SCREENS.COMMUNITY_MEMBERS.name, {
                communityId,
              })
            }
            communityDetailData={communityDetailData}
            isModeratorStatus={isModeratorStatus}
            handleJoin={handleJoin}
            handleLeave={handleLeave}
            isMemberStatus={isMemberStatus}
            navigateToEditCommunity={() =>
              navigation.navigate(SCREENS.EDIT_COMMUNITY.name, {
                communityId: communityId,
              })
            }
          />
        }
        ItemSeparatorComponent={Separator}
        ListEmptyComponent={EmptyList}
        onEndReachedThreshold={0.2}
        ListFooterComponent={isFetchingNextPage ? <Spinner /> : null}
        onEndReached={loadMore}
        refreshControl={
          <RefreshControl
            refreshing={refreshing}
            onRefresh={handleRefresh}
            tintColor={Colors[colorScheme].text}
          />
        }
      />
      {showFab && (
        <Fab
          onPress={() =>
            navigation.navigate(SCREENS.CREATE_COMMUNITY_POST.name, {
              community: communityDetailData,
            })
          }
          renderInPortal={false}
          placement="bottom-right"
          size="lg"
          icon={<Icon name="pencil" as={MaterialCommunityIcons} />}
          opacity={0.9}
          colorScheme="red"
        />
      )}
    </ErrorBoundary>
  );
};

export default CommunityScreen;
