import { ImagePickerAsset } from "expo-image-picker";
import { Platform } from "react-native";
import { ICommunity } from "types/index";
import request from "../utils/request";

// Create a new community
export const createCommunity = async (
  data: Partial<
    ICommunity & {
      latitude?: number;
      longitude?: number;
    }
  >
) => {
  return request({
    method: "post",
    url: "/communities",
    data: data,
  });
};

// Fetch a list of communities
export const getCommunities = async ({
  visibilityScope,
  latitude,
  longitude,
  countryCode,
  city,
  pageParam,
}: {
  visibilityScope: "local" | "city" | "country" | "global";
  latitude?: number;
  longitude?: number;
  countryCode?: string;
  city?: string;
  pageParam: number;
}) => {
  return request({
    method: "get",
    url: "/communities/explore",
    params: {
      visibilityScope,
      latitude,
      longitude,
      countryCode,
      city,
      page: pageParam,
    },
  });
};

// Fetch a single community by its ID
export const getCommunity = async (communityId: string) => {
  return request({
    method: "get",
    url: `/communities/${communityId}`,
  });
};

// Update a community's information
export const updateCommunity = async ({
  communityId,
  data,
}: {
  communityId: string;
  data: Partial<ICommunity>;
}) => {
  return request({
    method: "patch",
    url: `/communities/${communityId}`,
    data: data,
  });
};

// Join a community
export const joinCommunity = async (communityId: string) => {
  return request({
    method: "post",
    url: `/communities/${communityId}/join`,
  });
};

// Leave a community
export const leaveCommunity = async (communityId: string) => {
  return request({
    method: "post",
    url: `/communities/${communityId}/leave`,
  });
};

// Assign a community member as a moderator
export const assignModerator = async (
  communityId: string,
  memberId: string
) => {
  return request({
    method: "patch",
    url: `/communities/${communityId}/members/${memberId}/assign-moderator`,
  });
};

// Ban a community member
export const banMember = async (communityId: string, memberId: string) => {
  return request({
    method: "patch",
    url: `/communities/${communityId}/members/${memberId}/ban`,
  });
};

export const unbanMember = async (communityId: string, memberId: string) => {
  return request({
    method: "patch",
    url: `/communities/${communityId}/members/${memberId}/unban`,
  });
};

export const rejectPost = async (communityId: string, postId: string) => {
  return request({
    method: "patch",
    url: `/communities/${communityId}/posts/${postId}/reject`,
  });
};

export const isMember = async (communityId: string) => {
  return request({
    method: "get",
    url: `/communities/${communityId}/membership`,
  });
};

export const getCommunityFeed = async ({
  pageParam,
}: {
  pageParam: number;
}) => {
  return request({
    method: "get",
    url: "/communities/feed",
    params: {
      page: pageParam,
    },
  });
};

export const getCommunityPosts = async ({
  pageParam,
  communityId,
}: {
  pageParam: number;
  communityId: string;
}) => {
  return request({
    method: "get",
    url: `/communities/${communityId}/posts`,
    params: {
      page: pageParam,
    },
  });
};

export const getMyCommunities = async ({
  pageParam,
  limit,
}: {
  pageParam?: number;
  limit?: number;
}) => {
  return request({
    method: "get",
    url: "/communities/me",
    params: {
      populate: "community",
      page: pageParam,
      limit,
    },
  });
};

export const searchCommunities = async ({
  pageParam,
  q,
}: {
  pageParam: number;
  q: string;
}) => {
  return request({
    method: "get",
    url: "/communities/search",
    params: {
      q,
      page: pageParam,
    },
  });
};

export const uploadProfilePicture = async (
  communityId: string,
  image: ImagePickerAsset
) => {
  // Check if any file is selected or not
  if (image != null) {
    // If file selected then create FormData
    const data = new FormData();
    const fileType = image.uri.substring(image.uri.lastIndexOf(".") + 1);
    const uri =
      Platform.OS === "ios" ? image.uri.replace("file://", "") : image.uri;

    data.append("profileImage", {
      uri,
      name: image.fileName || `image.${fileType}`,
      type: `image/${fileType}`,
    } as any);

    return request({
      method: "post",
      url: `/communities/${communityId}/upload-profile-image`,
      data,
    });
  }
};

export const getCommunityMembers = async ({
  communityId,
  pageParam,
}: {
  communityId: string;
  pageParam: number;
}) => {
  return request({
    method: "get",
    url: `/communities/${communityId}/members`,
    params: {
      populate: "user",
      page: pageParam,
    },
  });
};
