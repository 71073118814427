import React, { useEffect, useRef, useState } from "react";
import {
  NavigationContainer,
  DefaultTheme,
  DarkTheme,
} from "@react-navigation/native";
import { Spinner, useColorMode, Center, StatusBar } from "native-base";
import * as Notifications from "expo-notifications";
import { Text } from "native-base";

import MainBottomTabNavigator from "./BottomTabNavigator";
import AuthNavigator from "./AuthNavigator";
import LinkingConfiguration from "./LinkingConfiguration";
import { navigationRef } from "./RootNavigation";
import { useAuthentication } from "hooks/useAuthentication";
import Colors from "constants/Colors";
import { ColorSchemeName, KeyboardAvoidingView, Platform } from "react-native";
import useThemeManager from "hooks/useThemeManager";

type NavigationProps = {
  colorScheme?: ColorSchemeName;
};

export default function Navigation({ colorScheme }: NavigationProps) {
  const routeNameRef = useRef<string | undefined>();
  const [, setNotification] = useState({});

  useEffect(() => {
    const backgroundSubscription =
      Notifications.addNotificationResponseReceivedListener(() => {
        // Handle background notification
      });

    const foregroundSubscription =
      Notifications.addNotificationReceivedListener((notification) => {
        // Handle foreground notification
        setNotification(notification);
      });

    return () => {
      foregroundSubscription.remove();
      backgroundSubscription.remove();
    };
  }, []);

  return (
    <NavigationContainer
      fallback={<Text>Loading...</Text>}
      ref={navigationRef}
      linking={LinkingConfiguration}
      theme={colorScheme === "dark" ? DarkTheme : DefaultTheme}
      onReady={() => {
        const route = navigationRef.getCurrentRoute();
        if (route) {
          routeNameRef.current = route.name;
        }
      }}
    >
      <RootNavigator />
    </NavigationContainer>
  );
}

function RootNavigator() {
  const { user } = useAuthentication();

  const { colorMode } = useColorMode();
  const colorScheme = colorMode || "light";
  const { currentStatusBarStyle } = useThemeManager();

  if (user === "init") {
    return (
      <Center style={{ flex: 1 }} bg={Colors[colorScheme].background}>
        <Spinner />
      </Center>
    );
  }

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "padding" : "height"}
      style={{
        flex: 1,
        backgroundColor: Colors[colorScheme].background,
      }}
    >
      <StatusBar
        barStyle={currentStatusBarStyle}
        translucent
        backgroundColor="transparent"
      />
      {user ? <MainBottomTabNavigator /> : <AuthNavigator />}
    </KeyboardAvoidingView>
  );
}
