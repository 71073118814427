// context/UserContext.tsx
import { createContext, useContext, useState, ReactNode } from "react";

interface User {
  avatarChar: string;
  username: string;
}

interface UserContextType {
  user: User | null;
  setUser: (user: User | null) => void;
  updateUserContext: (updatedUser: Partial<User>) => void;
}

const UserContext = createContext<UserContextType>({
  user: null,
  setUser: () => null,
  updateUserContext: () => null,
});

export const useUserContext = () => useContext(UserContext);

interface UserProviderProps {
  children: ReactNode;
}

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);

  const updateUserContext = (updatedUser: any) => {
    setUser((currentUser) => ({
      ...currentUser,
      ...updatedUser,
    }));
  };

  return (
    <UserContext.Provider value={{ user, setUser, updateUserContext }}>
      {children}
    </UserContext.Provider>
  );
};
