import { MaterialCommunityIcons } from "@expo/vector-icons";
import React from "react";
import { TouchableOpacity } from "react-native";
import { Row, Text } from "native-base";
import Colors from "constants/Colors";

const ICON_SIZE = 20;

interface VotesProps {
  onUpVotePress: () => void;
  onDownVotePress: () => void;
  count: number;
  userVote?: -1 | 1;
}

const Votes = ({
  count,
  onDownVotePress,
  onUpVotePress,
  userVote,
}: VotesProps) => {
  const displayCount = count && (count > 0 || count < -4);
  const activeUpVote = userVote === 1;
  const activeDownVote = userVote === -1;

  const countColor = (() => {
    if (activeUpVote) return Colors.light.danger;
    if (activeDownVote) return Colors.light.secondary;
    return Colors.light.secondary_text;
  })();

  return (
    <Row alignItems="center">
      <TouchableOpacity onPress={onUpVotePress} hitSlop={{ left: 5, right: 5 }}>
        {activeUpVote ? (
          <MaterialCommunityIcons
            name="arrow-up-bold"
            size={ICON_SIZE}
            color={Colors.light.danger}
          />
        ) : (
          <MaterialCommunityIcons
            name="arrow-up-bold-outline"
            size={ICON_SIZE}
            color={Colors.light.secondary_text}
          />
        )}
      </TouchableOpacity>
      <Text mx={2} color={countColor}>
        {displayCount ? count : "Vote"}
      </Text>
      <TouchableOpacity
        onPress={onDownVotePress}
        hitSlop={{ left: 5, right: 5 }}
      >
        {activeDownVote ? (
          <MaterialCommunityIcons
            name="arrow-down-bold"
            size={ICON_SIZE}
            color={Colors.light.secondary}
          />
        ) : (
          <MaterialCommunityIcons
            name="arrow-down-bold-outline"
            size={ICON_SIZE}
            color={Colors.light.secondary_text}
          />
        )}
      </TouchableOpacity>
    </Row>
  );
};

export default Votes;
