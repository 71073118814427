import { Platform } from "react-native";
import { CommunityPost } from "./types";
import request from "../utils/request";

const createFormData = (
  media: { uri: string }[],
  body: Record<string, any>
): FormData => {
  const data = new FormData();

  media.forEach((media, index) => {
    const fileType = media.uri
      .substring(media.uri.lastIndexOf(".") + 1)
      .toLowerCase();
    const isVideo = fileType.match(/^(mp4|mov|wmv|flv|avi)$/i);
    const type = isVideo ? `video/${fileType}` : `image/${fileType}`;
    const uri =
      Platform.OS === "ios" ? media.uri.replace("file://", "") : media.uri;

    data.append("media", {
      uri,
      name: `media_${index}.${fileType}`,
      type,
    } as any);
  });

  Object.keys(body).forEach((key) => {
    data.append(key, body[key]);
  });

  return data;
};

export const createPost = async (values: CommunityPost) => {
  let postData: any = {
    title: values.title,
    text: values.text,
    community: values.community,
  };

  // Append image data if it exists
  if (values.media?.length) {
    postData = createFormData(values.media, postData);
  }

  return request({
    method: "post",
    url: "/posts",
    data: postData,
  });
};

export const getMyPosts = async ({ pageParam }: { pageParam: number }) => {
  return request({
    method: "get",
    url: "/posts/me",
    params: {
      populate: "user",
      sortBy: "createdAt:desc",
      limit: 30,
      page: pageParam,
    },
  });
};

export const deletePost = (postId: string) => {
  return request({
    method: "delete",
    url: `/posts/${postId}`,
  });
};

export const reportPost = (postId: string) => {
  return request({
    method: "post",
    url: `posts/${postId}/report`,
  });
};

export const createPostVote = (postId: string, value: -1 | 1) => {
  return request({
    method: "post",
    url: `posts/${postId}/vote`,
    data: {
      value,
    },
  });
};

export const getPost = (postId: string) => {
  return request({
    method: "get",
    url: `/posts/${postId}`,
  });
};
