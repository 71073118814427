const tintColorLight = "#dd2f21";
const tintColorDark = "#fff";

export default {
  light: {
    text: "#222222",
    secondary_text: "#6C6C6C",
    background: "#f0f2f8",
    background_secondary: "#FBFCFF",
    separator: "#e8e8e8",
    tint: tintColorLight,
    tabIconDefault: "#ccc",
    tabIconSelected: tintColorLight,
    danger: "#dd2f21",
    danger_light: "#ffe8e6",
    success: "#8bd993",
    success_light: "#ebf9f0",
    secondary: "#74c5d4",
    secondary_light: "#ebf7fa",
    inputBackground: "#fff",
  },
  dark: {
    text: "#E4E6EB",
    secondary_text: "#B0B3B8",
    background: "#18191A",
    background_secondary: "#242526",
    separator: "#262626",
    tint: tintColorDark,
    tabIconDefault: "#ccc",
    tabIconSelected: tintColorDark,
    danger: "#dd2f21",
    danger_light: "#ffe8e6",
    success: "#8bd993",
    success_light: "#ebf9f0",
    secondary: "#74c5d4",
    secondary_light: "#ebf7fa",
    inputBackground: "#18191A",
  },
};
