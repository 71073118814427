import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { useMutation } from "@tanstack/react-query";
import {
  Button,
  CheckIcon,
  Column,
  FormControl,
  Input,
  ScrollView,
  Select,
  Spinner,
  TextArea,
  WarningOutlineIcon,
} from "native-base";
import * as Sentry from "sentry-expo";
import Toast from "react-native-toast-message";
import { NavigationProp, ParamListBase } from "@react-navigation/native";

import { createCommunity } from "../../api/community";
import SCREENS from "constants/Screen";
import { AxiosError } from "axios";
import { fetchLocation } from "utils/location";
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";

const validationSchema = yup.object().shape({
  name: yup.string().min(1).max(30).required("Community name is required"),
  description: yup
    .string()
    .min(1)
    .max(500)
    .required("Community description is required"),
  visibilityScope: yup.string().required("Community visibility is required"),
});

interface CreateCommunityProps {
  navigation: NavigationProp<ParamListBase>;
}

type VisibilityScope = "local" | "city" | "country" | "global";

const CreateCommunity = ({ navigation }: CreateCommunityProps) => {
  const [location, setLocation] = useState<{
    coords: {
      latitude: number;
      longitude: number;
    };
  } | null>(null);
  const [city, setCity] = useState<string | null>(null);
  const [countryCode, setCountryCode] = useState<string | null>(null);

  const mutation = useMutation({
    mutationFn: createCommunity,
    onError: (error: AxiosError) => {
      Sentry.Native.captureException(error);
      Toast.show({
        type: "error",
        text1: error.response?.data.message || "Something went wrong",
      });
    },
    onSuccess: ({ data }) => {
      navigation.navigate(SCREENS.COMMUNITY.name, {
        communityId: data.id,
      });

      Toast.show({
        type: "success",
        text1: "Community created",
        text2: "Your community has been created successfully",
        onHide: () => {
          // This function will be called when the first toast hides
          Toast.show({
            type: "info",
            text1: "New moderator",
            text2: "You are now a moderator of this community",
          });
        },
      });
    },
  });

  const formik = useFormik<{
    name: string;
    description: string;
    visibilityScope: VisibilityScope;
    latitude?: number;
    longitude?: number;
    city?: string;
    country?: string;
    countryCode?: string;
  }>({
    initialValues: {
      name: "",
      description: "",
      visibilityScope: "global",
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const requestData = { ...values };

        if (values.visibilityScope === "local" && location) {
          requestData.latitude = location.coords.latitude;
          requestData.longitude = location.coords.longitude;
        } else if (values.visibilityScope === "city" && city) {
          requestData.city = city;
        } else if (values.visibilityScope === "country" && countryCode) {
          requestData.countryCode = countryCode;
        }

        await mutation.mutateAsync(requestData);
        // Do something on success
      } catch (error) {
        // Handle error
        Toast.show({
          type: "error",
          text1: "Something went wrong",
        });
      }
    },
  });

  useEffect(() => {
    (async () => {
      try {
        const locationData = await fetchLocation();
        if (locationData) {
          setLocation({
            coords: {
              latitude: locationData.latitude,
              longitude: locationData.longitude,
            },
          });
          setCity(locationData.city);
          setCountryCode(locationData.countryCode);
        }
      } catch (error) {
        Toast.show({
          type: "error",
          text1: "Location Error",
          text2: "Could not fetch location data",
        });
      }
    })();
  }, [formik.values.visibilityScope]);

  return (
    <ErrorBoundary>
      <ScrollView flex={1} px={4} pt={2}>
        <Column mb={3} space={2}>
          <FormControl isInvalid={!!formik.errors.name && formik.touched.name}>
            <FormControl.Label>Community name</FormControl.Label>
            <Input
              value={formik.values.name}
              onChangeText={formik.handleChange("name")}
              onBlur={formik.handleBlur("name")}
            />
            <FormControl.ErrorMessage
              leftIcon={<WarningOutlineIcon size="xs" />}
            >
              {formik.errors.name}
            </FormControl.ErrorMessage>
          </FormControl>
          <FormControl
            isInvalid={
              !!formik.errors.description && formik.touched.description
            }
          >
            <FormControl.Label>Community description</FormControl.Label>
            <TextArea
              autoCompleteType
              value={formik.values.description}
              onChangeText={formik.handleChange("description")}
              onBlur={formik.handleBlur("description")}
            />
            <FormControl.ErrorMessage
              leftIcon={<WarningOutlineIcon size="xs" />}
            >
              {formik.errors.description}
            </FormControl.ErrorMessage>
          </FormControl>
          <FormControl
            isInvalid={
              !!formik.errors.visibilityScope && formik.touched.visibilityScope
            }
          >
            <FormControl.Label>Community Visibility</FormControl.Label>
            <Select
              selectedValue={formik.values.visibilityScope}
              minWidth={200}
              accessibilityLabel="Visibility"
              placeholder="Select Visibility"
              onValueChange={(itemValue) =>
                formik.setFieldValue("visibilityScope", itemValue)
              }
              _selectedItem={{
                bg: "teal.600",
                endIcon: <CheckIcon size={4} />,
              }}
            >
              <Select.Item
                label="Local - visible around your location"
                value="local"
              />
              <Select.Item
                label="City - visible to people in your city"
                value="city"
              />
              <Select.Item
                label="Country - visible to people in your country"
                value="country"
              />
              <Select.Item
                label="Global - visible to everyone"
                value="global"
              />
            </Select>
            <FormControl.ErrorMessage
              leftIcon={<WarningOutlineIcon size="xs" />}
            >
              {formik.errors.visibilityScope}
            </FormControl.ErrorMessage>
          </FormControl>
        </Column>
        {mutation.isPending ? (
          <Spinner />
        ) : (
          <Button
            onPress={() => formik.handleSubmit()}
            isDisabled={!formik.isValid || mutation.isPending}
          >
            Create Community
          </Button>
        )}
      </ScrollView>
    </ErrorBoundary>
  );
};

export default CreateCommunity;
