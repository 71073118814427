import React from "react";
import { AntDesign, Ionicons } from "@expo/vector-icons";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { createStackNavigator } from "@react-navigation/stack";
import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import { Box, Button, Icon, Row, useColorMode } from "native-base";

import Colors from "constants/Colors";
import CommunityFeedScreen from "../screens/CommnuityFeed";
import {
  BottomTabParamList,
  CommunityParamList,
  CommunityTopTabParamList,
  HomeParamList,
  ProfileParamList,
  ProfileTopTabParamList,
} from "./types";
import MediaGalleryScreen from "../screens/MediaGallery";
import CommentsScreen from "../screens/Comments";
import MyPostScreen from "../screens/MyPosts";
import RepliesScreen from "../screens/Replies";
import ProfileCard from "components/ProfileCard/ProfileCard";
import MyProfileScreen from "../screens/EditProfile";
import CommunityScreen from "../screens/Community";
import CreateCommunityScreen from "../screens/CreateCommunity";
import SCREENS from "constants/Screen";
import CreateCommunityPost from "../screens/CreateCommunityPost";
import MyCommunityList from "../screens/MyCommunityList/MyCommunityList";
import Notifications from "../screens/Notifications";
import SearchCommunities from "../screens/SearchCommunities";
import EditCommunity from "../screens/EditCommunity";
import WebViewScreen from "../screens/WebView";
import CommunityMembers from "../screens/CommunityMembers";
import GlobalCommunities from "../screens/ExploreCommunities/ByLocation/Global";
import CountryCommunities from "../screens/ExploreCommunities/ByLocation/Country";
import LocalCommunities from "../screens/ExploreCommunities/ByLocation/Local";
import CityCommunities from "../screens/ExploreCommunities/ByLocation/City";
import * as RootNavigation from "./RootNavigation";

function TabBarIcon(props: {
  name: React.ComponentProps<typeof Ionicons>["name"];
  color: string;
}) {
  return <Ionicons size={30} style={{ marginBottom: -3 }} {...props} />;
}

function useCurrentColorScheme() {
  const { colorMode } = useColorMode();
  return colorMode || "light";
}

const CommunitiesStack = createStackNavigator<CommunityParamList>();
function CommunitiesStackNavigator() {
  const colorScheme = useCurrentColorScheme();

  return (
    <CommunitiesStack.Navigator
      screenOptions={{
        headerStyle: {
          backgroundColor: Colors[colorScheme].background,
        },
        headerTintColor: Colors[colorScheme].text,
        gestureEnabled: false,
      }}
      initialRouteName={SCREENS.EXPLORE_COMMUNITIES.name}
    >
      <CommunitiesStack.Screen
        name={SCREENS.EXPLORE_COMMUNITIES.name}
        component={CommunityTopTabNavigator}
        options={{
          headerTitle: SCREENS.EXPLORE_COMMUNITIES.title,
          headerRight: () => (
            <Row>
              <Button
                variant="unstyled"
                onPress={() =>
                  RootNavigation.navigate(SCREENS.SEARCH_COMMUNITIES.name, {})
                }
                accessibilityLabel="Search Communities"
              >
                <Icon
                  as={Ionicons}
                  name="search-outline"
                  size="sm"
                  color={Colors[colorScheme].text}
                />
              </Button>
              <Button
                variant="unstyled"
                onPress={() =>
                  RootNavigation.navigate(SCREENS.CREATE_COMMUNITY.name, {})
                }
                accessibilityLabel="Create Community"
              >
                <Icon
                  as={AntDesign}
                  name="addusergroup"
                  size="sm"
                  color={Colors[colorScheme].text}
                />
              </Button>
            </Row>
          ),
        }}
      />
      <CommunitiesStack.Screen
        name={SCREENS.COMMUNITY.name}
        component={CommunityScreen}
      />
      <CommunitiesStack.Screen
        name={SCREENS.CREATE_COMMUNITY_POST.name}
        component={CreateCommunityPost}
        options={{
          headerTitle: SCREENS.CREATE_COMMUNITY_POST.title,
        }}
      />
      <CommunitiesStack.Screen
        name={SCREENS.CREATE_COMMUNITY.name}
        component={CreateCommunityScreen}
        options={{
          headerTitle: SCREENS.CREATE_COMMUNITY.title,
        }}
      />
      <CommunitiesStack.Screen
        name={SCREENS.SEARCH_COMMUNITIES.name}
        component={SearchCommunities}
        options={{
          headerTitle: SCREENS.SEARCH_COMMUNITIES.title,
        }}
      />
      <CommunitiesStack.Screen
        name={SCREENS.EDIT_COMMUNITY.name}
        component={EditCommunity}
        options={{
          headerTitle: SCREENS.EDIT_COMMUNITY.title,
        }}
      />
      <CommunitiesStack.Screen
        name={SCREENS.WEB_VIEW.name}
        component={WebViewScreen}
        options={({ route }) => ({
          headerTitle: route.params.uri,
        })}
      />
      <CommunitiesStack.Screen
        name={SCREENS.COMMUNITY_MEMBERS.name}
        component={CommunityMembers}
        options={{
          headerTitle: SCREENS.COMMUNITY_MEMBERS.title,
        }}
      />
      <CommunitiesStack.Screen
        name={SCREENS.MEDIA_GALLERY.name}
        component={MediaGalleryScreen}
        options={{
          headerTitle: SCREENS.MEDIA_GALLERY.title,
        }}
      />
    </CommunitiesStack.Navigator>
  );
}

const HomeStack = createStackNavigator<HomeParamList>();
function HomeStackNavigator() {
  const colorScheme = useCurrentColorScheme();

  return (
    <HomeStack.Navigator
      screenOptions={{
        headerStyle: {
          backgroundColor: Colors[colorScheme].background,
        },
        headerTintColor: Colors[colorScheme].text,
        gestureEnabled: false,
      }}
      initialRouteName={SCREENS.COMMUNITY_FEED.name}
    >
      <HomeStack.Screen
        name={SCREENS.COMMUNITY_FEED.name}
        component={CommunityFeedScreen}
        options={{
          headerTitle: SCREENS.COMMUNITY_FEED.title,
          headerShown: false,
        }}
      />

      <HomeStack.Screen
        name={SCREENS.MEDIA_GALLERY.name}
        component={MediaGalleryScreen}
        options={{
          headerTitle: SCREENS.MEDIA_GALLERY.title,
        }}
      />
      <HomeStack.Screen
        name={SCREENS.POST_COMMENTS.name}
        component={CommentsScreen}
        options={{ headerTitle: SCREENS.POST_COMMENTS.title }}
      />
      <HomeStack.Screen
        name={SCREENS.MY_COMMUNITY_LIST.name}
        component={MyCommunityList}
        options={{
          headerTitle: SCREENS.MY_COMMUNITY_LIST.title,
        }}
      />
      <HomeStack.Screen
        name={SCREENS.CREATE_COMMUNITY.name}
        component={CreateCommunityScreen}
        options={{
          headerTitle: SCREENS.CREATE_COMMUNITY.title,
        }}
      />
      <HomeStack.Screen
        name={SCREENS.COMMUNITY.name}
        component={CommunityScreen}
      />
      <HomeStack.Screen
        name={SCREENS.CREATE_COMMUNITY_POST.name}
        component={CreateCommunityPost}
        options={{
          headerTitle: SCREENS.CREATE_COMMUNITY_POST.title,
        }}
      />
      <HomeStack.Screen
        name={SCREENS.EDIT_COMMUNITY.name}
        component={EditCommunity}
        options={{
          headerTitle: SCREENS.EDIT_COMMUNITY.title,
        }}
      />
      <HomeStack.Screen
        name={SCREENS.WEB_VIEW.name}
        component={WebViewScreen}
        options={({ route }) => ({
          headerTitle: route.params.uri,
        })}
      />
      <HomeStack.Screen
        name={SCREENS.COMMUNITY_MEMBERS.name}
        component={CommunityMembers}
        options={{
          headerTitle: SCREENS.COMMUNITY_MEMBERS.title,
        }}
      />
    </HomeStack.Navigator>
  );
}

const BottomTab = createBottomTabNavigator<BottomTabParamList>();
function MainBottomTabNavigator() {
  const colorScheme = useCurrentColorScheme();

  return (
    <Box maxW="48rem" flex={1} mx="auto" w="full">
      <BottomTab.Navigator
        screenOptions={{
          headerShown: false,
          tabBarActiveTintColor: Colors[colorScheme].tint,
          tabBarStyle: {
            backgroundColor: Colors[colorScheme].background,
          },
          tabBarHideOnKeyboard: true,
        }}
      >
        <BottomTab.Screen
          name={SCREENS.HOME.name}
          component={HomeStackNavigator}
          options={{
            headerTitle: SCREENS.HOME.title,
            tabBarIcon: ({ color, focused }) => (
              <TabBarIcon
                name={focused ? "home" : "home-outline"}
                color={color}
              />
            ),
          }}
        />
        <BottomTab.Screen
          name={SCREENS.COMMUNITIES.name}
          component={CommunitiesStackNavigator}
          options={{
            headerTitle: SCREENS.COMMUNITIES.title,
            tabBarIcon: ({ color, focused }) => (
              <TabBarIcon
                name={focused ? "people" : "people-outline"}
                color={color}
              />
            ),
          }}
        />
        <BottomTab.Screen
          name={SCREENS.NOTIFICATIONS.name}
          component={Notifications}
          options={{
            headerTitle: SCREENS.NOTIFICATIONS.title,
            tabBarIcon: ({ color, focused }) => (
              <TabBarIcon
                name={focused ? "notifications" : "notifications-outline"}
                color={color}
              />
            ),
          }}
        />
        <BottomTab.Screen
          name={SCREENS.PROFILE.name}
          component={ProfileStackNavigator}
          options={{
            headerTitle: SCREENS.PROFILE.title,
            tabBarIcon: ({ color, focused }) => (
              <TabBarIcon
                name={focused ? "person" : "person-outline"}
                color={color}
              />
            ),
          }}
        />
      </BottomTab.Navigator>
    </Box>
  );
}

const ProfileStack = createStackNavigator<ProfileParamList>();
function ProfileStackNavigator() {
  const colorScheme = useCurrentColorScheme();

  return (
    <ProfileStack.Navigator
      screenOptions={{
        headerStyle: { backgroundColor: Colors[colorScheme].background },
        headerTintColor: Colors[colorScheme].text,
        gestureEnabled: false,
      }}
      initialRouteName={SCREENS.ACCOUNT.name}
    >
      <ProfileStack.Screen
        name={SCREENS.ACCOUNT.name}
        component={ProfileTopTabNavigator}
        options={{
          headerTitle: SCREENS.ACCOUNT.title,
          headerShown: false,
        }}
      />

      <ProfileStack.Screen
        name={SCREENS.USER_PROFILE.name}
        component={MyProfileScreen}
        options={{
          headerTitle: SCREENS.USER_PROFILE.title,
        }}
      />
    </ProfileStack.Navigator>
  );
}

const ProfileTopTab = createMaterialTopTabNavigator<ProfileTopTabParamList>();
function ProfileTopTabNavigator() {
  const colorScheme = useCurrentColorScheme();

  return (
    <Box
      flex={1}
      safeAreaTop
      _dark={{
        bg: Colors.dark.background,
      }}
      _light={{
        bg: Colors.light.background,
      }}
    >
      <ProfileCard />
      <ProfileTopTab.Navigator
        screenOptions={{
          tabBarActiveTintColor: Colors[colorScheme].tint,
          tabBarStyle: {
            backgroundColor: Colors[colorScheme].background,
          },
          tabBarIndicatorStyle: {
            backgroundColor: Colors[colorScheme].tint,
          },
        }}
      >
        <ProfileTopTab.Screen
          name={SCREENS.MY_POSTS.name}
          component={MyPostScreen}
          options={{ title: SCREENS.MY_POSTS.title }}
        />
        <ProfileTopTab.Screen
          name={SCREENS.REPLIES.name}
          component={RepliesScreen}
          options={{ title: SCREENS.REPLIES.title }}
        />
      </ProfileTopTab.Navigator>
    </Box>
  );
}

const CommunityTopTab =
  createMaterialTopTabNavigator<CommunityTopTabParamList>();
function CommunityTopTabNavigator() {
  const colorScheme = useCurrentColorScheme();

  return (
    <CommunityTopTab.Navigator
      screenOptions={{
        tabBarActiveTintColor: Colors[colorScheme].tint,
        tabBarStyle: {
          backgroundColor: Colors[colorScheme].background,
        },
        tabBarIndicatorStyle: {
          backgroundColor: Colors[colorScheme].tint,
        },
      }}
    >
      <CommunityTopTab.Screen
        name={SCREENS.GLOBAL_COMMUNITIES.name}
        component={GlobalCommunities}
        options={{ title: SCREENS.GLOBAL_COMMUNITIES.title }}
      />
      <CommunityTopTab.Screen
        name={SCREENS.COUNTRY_COMMUNITIES.name}
        component={CountryCommunities}
        options={{ title: SCREENS.COUNTRY_COMMUNITIES.title }}
      />
      <CommunityTopTab.Screen
        name={SCREENS.CITY_COMMUNITIES.name}
        component={CityCommunities}
        options={{ title: SCREENS.CITY_COMMUNITIES.title }}
      />
      <CommunityTopTab.Screen
        name={SCREENS.LOCAL_COMMUNITIES.name}
        component={LocalCommunities}
        options={{ title: SCREENS.LOCAL_COMMUNITIES.title }}
      />
    </CommunityTopTab.Navigator>
  );
}

export default MainBottomTabNavigator;
