import React, { useState } from "react";
import { Alert, StyleSheet, TouchableOpacity, Switch } from "react-native";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import Toast from "react-native-toast-message";
import { AntDesign } from "@expo/vector-icons";
import * as Sentry from "sentry-expo";
import {
  Box,
  Button,
  FormControl,
  ScrollView,
  VStack,
  Text,
  Input,
  Spinner,
  Row,
  Center,
} from "native-base";
import { AxiosError } from "axios";

import Colors from "constants/Colors";
import { getCurrentUser, updateUser, deleteUser } from "../api/user";
import { logout, updateUserPushToken } from "../api/auth";
import { useUserContext } from "../context/UserContext";
import useThemeManager from "hooks/useThemeManager";
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";

const CURRENT_USER_QUERY_KEY = "currentUser";

export default function EditProfileScreen() {
  const [displayName, setDisplayName] = useState("");
  const [avatarChar, setAvatarChar] = useState("");

  // Add this line inside the EditProfileScreen component
  const { updateUserContext } = useUserContext();
  const { handleToggleColorMode, colorMode } = useThemeManager();

  const handleDeleteAccount = () => {
    Alert.alert(
      "Delete account",
      "Once your delete your account, all your information will be lost forever. We will not be able to restore your account. Are you sure you want to proceed?",
      [
        {
          text: "No",
          onPress: () => null,
          style: "cancel",
        },
        {
          text: "Yes",
          onPress: deleteUser,
          style: "destructive",
        },
      ]
    );
  };

  // Access the client
  const queryClient = useQueryClient();

  // Queries
  const {
    isPending,
    data: user,
    error,
  } = useQuery({
    queryKey: [CURRENT_USER_QUERY_KEY],
    queryFn: async () => {
      const { data } = await getCurrentUser();
      setDisplayName(data.displayName);
      setAvatarChar(data.avatarChar);
      return data;
    },
  });

  if (error) {
    Sentry.Native.captureException(error);
  }

  // Mutations
  const mutation = useMutation({
    mutationFn: updateUser,
    onSuccess: ({ data }) => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: [CURRENT_USER_QUERY_KEY] });

      // Update the UserContext with the updated user data
      updateUserContext(data);

      Toast.show({
        type: "success",
        text1: "Profile updated",
        position: "bottom",
      });
    },
    onError: (error: AxiosError) => {
      Sentry.Native.captureException(error);
      Toast.show({
        type: "error",
        text1: error.response?.data.message || "Something went wrong",
      });
    },
  });

  const expoTokenMutation = useMutation({
    mutationFn: updateUserPushToken,
    onSuccess: () => {
      Toast.show({
        type: "success",
        text1: "Push notifications enabled",
        position: "bottom",
      });

      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: [CURRENT_USER_QUERY_KEY] });
    },
    onError: (error: AxiosError) => {
      Sentry.Native.captureException(error);
      Toast.show({
        type: "error",
        text1: error.response?.data.message || "Something went wrong",
      });
    },
  });

  if (isPending) {
    return (
      <Center
        flex={1}
        _dark={{
          bg: Colors.dark.background,
        }}
        _light={{
          bg: Colors.light.background,
        }}
      >
        <Spinner />
      </Center>
    );
  }

  return (
    <ErrorBoundary>
      <Box flex={1}>
        <ScrollView p={5}>
          <TouchableOpacity
            style={{ marginLeft: "auto", padding: 15 }}
            onPress={handleDeleteAccount}
          >
            <AntDesign name="delete" size={20} color={Colors.light.danger} />
          </TouchableOpacity>
          <Box style={styles.avatar}>
            <Text fontSize="3xl">{user?.avatarChar}</Text>
          </Box>
          <VStack space={3}>
            <FormControl>
              <FormControl.Label>Display Name</FormControl.Label>
              <Input
                size="xl"
                autoCorrect={false}
                onChangeText={setDisplayName}
                value={displayName}
              />
            </FormControl>
            <FormControl>
              <FormControl.Label>Avatar</FormControl.Label>
              <Input
                size="xl"
                autoCorrect={false}
                onChangeText={setAvatarChar}
                value={avatarChar}
              />
            </FormControl>

            <Row alignItems="center" space={4}>
              <Text>Dark mode</Text>
              <Switch
                onValueChange={handleToggleColorMode}
                value={colorMode === "dark"}
              />
            </Row>

            {user.expoPushToken ? null : (
              <Button
                size="sm"
                variant="outline"
                onPress={() => expoTokenMutation.mutate()}
                alignSelf="flex-start"
              >
                Enable Push Notifications
              </Button>
            )}

            <Button
              onPress={() => {
                mutation.mutate({ displayName, avatarChar });
              }}
              isLoading={mutation.isPending}
            >
              Update
            </Button>
            <Button variant="ghost" onPress={logout}>
              Sign out
            </Button>
          </VStack>
        </ScrollView>
      </Box>
    </ErrorBoundary>
  );
}

const styles = StyleSheet.create({
  avatar: {
    borderRadius: 100,
    paddingHorizontal: 22,
    paddingVertical: 15,
    borderWidth: 1,
    borderColor: Colors.light.secondary_text,
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
  },
});
