import * as React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import RegisterScreen from "../screens/Register";
import LoginScreen from "../screens/Login";
import PasswordResetScreen from "../screens/PasswordReset";
import SCREENS from "constants/Screen";
import WebViewScreen from "../screens/WebView";
import Colors from "constants/Colors";
import { Box, useColorMode } from "native-base";
import { AuthStackParamList } from "./types";
import CommentsScreen from "../screens/Comments";
import MediaGalleryScreen from "../screens/MediaGallery";

const AuthStack = createStackNavigator<AuthStackParamList>();

export default function AuthNavigator() {
  const { colorMode } = useColorMode();

  const colorScheme = colorMode || "light";

  return (
    <Box flex={1} maxW="700px" w="full" mx="auto">
      <AuthStack.Navigator
        initialRouteName={SCREENS.COMMUNITY_FEED.name}
        screenOptions={{
          headerStyle: { backgroundColor: Colors[colorScheme].background },
          headerTintColor: Colors[colorScheme].text,
        }}
      >
        <AuthStack.Screen
          name={SCREENS.LOGIN.name}
          options={{
            headerTitle: "",
          }}
          component={LoginScreen}
        />
        <AuthStack.Screen
          name={SCREENS.REGISTER.name}
          component={RegisterScreen}
          options={{
            headerTitle: "",
          }}
        />
        <AuthStack.Screen
          name={SCREENS.PASSWORD_RESET.name}
          component={PasswordResetScreen}
          options={{
            headerTitle: "",
          }}
        />
        <AuthStack.Screen
          name={SCREENS.WEB_VIEW.name}
          component={WebViewScreen}
          options={({ route }) => ({
            headerTitle: route.params.uri,
          })}
        />
        <AuthStack.Screen
          name={SCREENS.POST_COMMENTS.name}
          component={CommentsScreen}
          options={{ headerTitle: SCREENS.POST_COMMENTS.title }}
        />
        <AuthStack.Screen
          name={SCREENS.MEDIA_GALLERY.name}
          component={MediaGalleryScreen}
          options={{
            headerTitle: SCREENS.MEDIA_GALLERY.title,
          }}
        />
      </AuthStack.Navigator>
    </Box>
  );
}
