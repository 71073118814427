import AsyncStorage from "@react-native-async-storage/async-storage";
import { StorageManager, extendTheme } from "native-base";
import * as Sentry from "sentry-expo";
import Colors from "constants/Colors";

type ColorMode = "dark" | "light";

interface ColorConfig {
  bg: {
    base: string;
    surface: string;
  };
  text: {
    primary: string;
    secondary: string;
  };
}

interface CustomColors {
  dark: ColorConfig;
  light: ColorConfig;
}

const customColors: CustomColors = {
  dark: {
    // Background Colors
    bg: {
      base: Colors.dark.background,
      surface: Colors.dark.background_secondary,
    },
    // Text Colors
    text: {
      primary: Colors.dark.text,
      secondary: Colors.dark.secondary_text,
    },
  },
  light: {
    // Background Colors
    bg: {
      base: Colors.light.background,
      surface: Colors.light.background_secondary,
    },
    // Text Colors
    text: {
      primary: Colors.light.text,
      secondary: Colors.light.secondary_text,
    },
  },
};

type BgKey = "base" | "surface";
type TextKey = "primary" | "secondary";
type ColorType = "bg" | "text";

const getColor = (
  colorMode: ColorMode,
  type: ColorType,
  key: BgKey | TextKey
): string => {
  if (type === "bg") {
    return customColors[colorMode][type][key as BgKey];
  }
  return customColors[colorMode][type][key as TextKey];
};

// Define the colorModeManager,
// here we are using react-native-async-storage (https://react-native-async-storage.github.io/async-storage/)
export const colorModeManager: StorageManager = {
  get: async () => {
    try {
      const val = await AsyncStorage.getItem("@color-mode");
      return val === "dark" ? "dark" : "light";
    } catch (e) {
      return "light";
    }
  },
  set: async (value: ColorMode) => {
    try {
      await AsyncStorage.setItem("@color-mode", value);
    } catch (e) {
      Sentry.Native.captureException(e);
    }
  },
};

// Define the theme
export const theme = extendTheme({
  useSystemColorMode: false,
  initialColorMode: "light",
  fontConfig: {
    Airbnb: {
      400: {
        normal: "airbnb-cereal-book",
      },
      500: {
        normal: "airbnb-cereal-medium",
      },
      600: {
        normal: "airbnb-cereal-bold",
      },
      700: {
        normal: "airbnb-cereal-bold",
      },
    },
  },

  // Make sure values below matches any of the keys in `fontConfig`
  fonts: {
    heading: "Airbnb",
    body: "Airbnb",
    mono: "Airbnb",
    bold: "Airbnb",
  },
  components: {
    Spinner: {
      baseStyle: ({ colorMode }: { colorMode: ColorMode }) => ({
        color: getColor(colorMode, "text", "primary"),
      }),
    },
    ScrollView: {
      baseStyle: ({ colorMode }: { colorMode: ColorMode }) => ({
        backgroundColor: getColor(colorMode, "bg", "base"),
      }),
    },
    FlatList: {
      baseStyle: ({ colorMode }: { colorMode: ColorMode }) => ({
        backgroundColor: getColor(colorMode, "bg", "base"),
      }),
    },
    Text: {
      baseStyle: ({ colorMode }: { colorMode: ColorMode }) => ({
        color: getColor(colorMode, "text", "primary"),
      }),
    },
    Button: {
      baseStyle: ({ colorMode }: { colorMode: ColorMode }) => ({
        _text: {
          color: getColor(colorMode, "text", "primary"),
        },
        _disabled: {
          opacity: 0.4,
        },
      }),
      variants: {
        outline: () => ({
          _light: { borderColor: "red.500" },
          _dark: { borderColor: "red.500" },
        }),
      },
      defaultProps: {
        colorScheme: "red",
      },
    },
    Input: {
      baseStyle: ({ colorMode }: { colorMode: ColorMode }) => ({
        backgroundColor:
          colorMode === "dark"
            ? "transparent"
            : getColor("light", "bg", "base"),
        _dark: {
          _focus: {
            borderColor: "muted.400",
          },
        },
        _light: {
          _focus: {
            borderColor: "pink.200",
          },
        },
      }),
    },
  },
});
