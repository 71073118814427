import { ReactNode } from "react";
import RNErrorBoundary from "react-native-error-boundary";
import * as Sentry from "sentry-expo";
import ErrorComponent from "components/ErrorComponent/ErrorComponent";

const errorHandler = (error: Error) => {
  Sentry.Native.captureException(error);
};

const CustomFallback = (props: { error: Error; resetError: () => void }) => (
  <ErrorComponent
    message={props.error.message}
    onRetry={() => props.resetError()}
  />
);

interface ErrorBoundaryProps {
  children: Exclude<NonNullable<ReactNode>, string | number | boolean>;
}

const ErrorBoundary = ({ children }: ErrorBoundaryProps) => (
  <RNErrorBoundary FallbackComponent={CustomFallback} onError={errorHandler}>
    {children}
  </RNErrorBoundary>
);

export default ErrorBoundary;
