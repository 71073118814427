// PostList.js
import React, { useCallback } from "react";
import { FlatList, Spinner } from "native-base";
import { ListRenderItem, RefreshControl } from "react-native";
import { IPost } from "types/index";
import Post from "components/Post/Post";
import Separator from "components/Separator/Separator";
import EmptyList from "../EmptyList/EmptyList";
import Colors from "constants/Colors";
import useVoteMutationList from "hooks/useVoteMutationList";

const COMMUNITY_FEED_QUERY_NAME = "communityFeed";

interface PostListProps {
  data: IPost[];
  isFetchingNextPage: boolean;
  loadMore: () => void;
  refreshing: boolean;
  handleRefresh: () => Promise<void>;
  colorScheme: "light" | "dark";
  listHeaderComponent?:
    | React.ComponentType<unknown>
    | React.ReactElement
    | null
    | undefined;
}

export const PostList = ({
  data,
  isFetchingNextPage,
  loadMore,
  refreshing,
  handleRefresh,
  colorScheme,
  listHeaderComponent,
}: PostListProps) => {
  const voteMutation = useVoteMutationList(COMMUNITY_FEED_QUERY_NAME);

  const renderPost: ListRenderItem<IPost> = useCallback(({ item }) => {
    // Function to render the Post component
    const renderPostComponent = () => (
      <Post
        data={item}
        queryName={COMMUNITY_FEED_QUERY_NAME}
        truncate
        onDownVotePress={() =>
          voteMutation.mutateAsync({
            postId: item.id,
            vote: -1,
          })
        }
        onUpVotePress={() =>
          voteMutation.mutateAsync({
            postId: item.id,
            vote: 1,
          })
        }
      />
    );

    return renderPostComponent();
  }, []);

  return (
    <FlatList
      ListHeaderComponent={listHeaderComponent}
      data={data}
      renderItem={renderPost}
      keyExtractor={(item, index) => item.id || index.toString()}
      ItemSeparatorComponent={Separator}
      ListEmptyComponent={<EmptyList />}
      onEndReachedThreshold={0.3}
      ListFooterComponent={isFetchingNextPage ? <Spinner /> : null}
      onEndReached={loadMore}
      refreshControl={
        <RefreshControl
          refreshing={refreshing}
          onRefresh={handleRefresh}
          tintColor={Colors[colorScheme].text}
        />
      }
    />
  );
};

export default PostList;
