import React, { useState } from "react";
import {
  StyleSheet,
  Alert,
  TouchableWithoutFeedback,
  RefreshControl,
} from "react-native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import {
  useMutation,
  useInfiniteQuery,
  useQueryClient,
} from "@tanstack/react-query";
import * as Sentry from "sentry-expo";
import {
  Box,
  Spinner,
  Text,
  FlatList,
  Center,
  useColorMode,
} from "native-base";
import Toast from "react-native-toast-message";
import { AxiosError } from "axios";
import { NavigationProp, ParamListBase } from "@react-navigation/native";

import Post from "components/PostContent/PostContent";
import Separator from "components/Separator/Separator";
import EmptyList from "components/EmptyList/EmptyList";
import Colors from "constants/Colors";
import { getMyPosts, deletePost } from "../api/post";
import { IPost } from "types/index";
import SCREENS from "constants/Screen";
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";
// import { useRefetchOnFocus } from "hooks/useRefetchOnFocus";

const MY_POSTS_QUERY_KEY = "myPosts";

interface MyPostProps {
  navigation: NavigationProp<ParamListBase>;
}

const MyPostScreen = ({ navigation }: MyPostProps) => {
  const [refreshing, setRefreshing] = useState(false);
  const { colorMode } = useColorMode();
  const colorScheme = colorMode || "light";

  // Queries
  const {
    isPending,
    data,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    refetch,
  } = useInfiniteQuery({
    queryKey: [MY_POSTS_QUERY_KEY],
    queryFn: async ({ pageParam = 1 }) => {
      const { data } = await getMyPosts({ pageParam });
      return data;
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      if (lastPage.page + 1 > lastPage.totalPages) {
        return null;
      }

      return lastPage.page + 1;
    },
  });

  const handleRefresh = async () => {
    setRefreshing(true);
    await refetch();
    setRefreshing(false);
  };

  // Access the client
  const queryClient = useQueryClient();

  // Mutations
  const mutation = useMutation({
    mutationFn: deletePost,
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: [MY_POSTS_QUERY_KEY] });
      Toast.show({
        type: "success",
        text1: "Post deleted",
      });
    },
    onError: (error: AxiosError) => {
      Sentry.Native.captureException(error);
      Toast.show({
        type: "error",
        text1: error.response?.data.message || "Something went wrong",
      });
    },
  });

  const deletePostConfirmation = (id: string) => {
    Alert.alert("Delete post", "Are you sure you want to delete this post?", [
      {
        text: "Cancel",
        onPress: () => {
          null;
        },
        style: "cancel",
      },
      {
        text: "Delete",
        style: "destructive",
        onPress: () => {
          mutation.mutate(id);
        },
      },
    ]);
  };

  const loadMore = () => {
    if (hasNextPage) {
      fetchNextPage();
    }
  };

  const navigateToComments = (postId: string) => {
    navigation.navigate(SCREENS.POST_COMMENTS.name, { postId });
  };

  const renderPost = ({ item }: { item: IPost }) => (
    <Post
      truncate
      onPress={() => navigateToComments(item.id)}
      data={item}
      actions={
        <>
          {item.numberOfComments ? (
            <TouchableWithoutFeedback
              onPress={() => navigateToComments(item.id)}
            >
              <Box style={styles.postActionBtn}>
                <MaterialCommunityIcons
                  name="comment-outline"
                  size={18}
                  color={Colors.light.secondary_text}
                />
                <Text style={styles.postActionBtnText}>
                  {item.numberOfComments}
                </Text>
              </Box>
            </TouchableWithoutFeedback>
          ) : null}
          <TouchableWithoutFeedback
            onPress={() => deletePostConfirmation(item.id)}
          >
            <Box style={styles.postActionBtn}>
              <MaterialCommunityIcons
                name="delete-outline"
                size={18}
                color={Colors.light.secondary_text}
              />
            </Box>
          </TouchableWithoutFeedback>
        </>
      }
    />
  );

  // useRefetchOnFocus(refetch);

  if (isPending)
    <Center
      flex={1}
      _dark={{
        bg: Colors.dark.background,
      }}
      _light={{
        bg: Colors.light.background,
      }}
    >
      <Spinner />
    </Center>;

  return (
    <ErrorBoundary>
      <FlatList
        px={4}
        data={data?.pages.flatMap((page) => page.results)}
        renderItem={renderPost}
        keyExtractor={(item, index) => item.id || index.toString()}
        ItemSeparatorComponent={Separator}
        ListEmptyComponent={EmptyList}
        onEndReachedThreshold={0.2}
        ListFooterComponent={isFetchingNextPage ? <Spinner /> : null}
        onEndReached={loadMore}
        refreshControl={
          <RefreshControl
            refreshing={refreshing}
            onRefresh={handleRefresh}
            tintColor={Colors[colorScheme].text}
          />
        }
      />
    </ErrorBoundary>
  );
};

export default MyPostScreen;

const styles = StyleSheet.create({
  userInfoContainer: {
    justifyContent: "center",
    alignItems: "center",
    marginVertical: 10,
  },
  avatar: { height: 80, width: 80, borderRadius: 40 },
  postActionBtn: {
    flexDirection: "row",
    padding: 5,
    alignItems: "center",
    marginRight: 10,
  },
  postActionBtnText: {
    color: Colors.light.secondary_text,
    marginLeft: 3,
    fontSize: 12,
  },
});
