// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { logEvent } from "firebase/analytics";
import {
  getAuth,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  onAuthStateChanged,
} from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBotmf5r30bq3xI0oOweuYaNjBmA0uZQHA",
  authDomain: "closefeed-production.firebaseapp.com",
  projectId: "closefeed-production",
  storageBucket: "closefeed-production.appspot.com",
  messagingSenderId: "1058980731966",
  appId: "1:1058980731966:web:17fc43ee8966f6f671456f",
  measurementId: "G-8FY93BQ10V",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
// const analytics = getAnalytics(app);

export {
  app,
  auth,
  signInWithEmailAndPassword,
  logEvent,
  sendPasswordResetEmail,
  onAuthStateChanged,
};
