import * as Linking from "expo-linking";
import SCREENS from "constants/Screen";

export default {
  prefixes: [
    Linking.createURL("/"),
    "closefeed://",
    "https://closefeed.com",
    "https://www.closefeed.com/*",
  ],
  config: {
    screens: {
      [SCREENS.HOME.name]: {
        screens: {
          [SCREENS.COMMUNITY_FEED.name]: "community-feed",
          [SCREENS.MEDIA_GALLERY.name]: "media-gallery",
          [SCREENS.POST_COMMENTS.name]: "post-comments/:postId",
          [SCREENS.MY_COMMUNITY_LIST.name]: "my-community-list",
          [SCREENS.CREATE_COMMUNITY.name]: "home-create-community",
        },
      },
      [SCREENS.PROFILE.name]: {
        screens: {
          [SCREENS.ACCOUNT.name]: "account",
          [SCREENS.USER_PROFILE.name]: "user-profile",
        },
      },
      [SCREENS.COMMUNITIES.name]: {
        screens: {
          [SCREENS.EXPLORE_COMMUNITIES.name]: "explore-communities",
          [SCREENS.COMMUNITY.name]: "community/:communityId",
          [SCREENS.CREATE_COMMUNITY.name]: "create-community",
          [SCREENS.COMMUNITY_SETTINGS.name]: "community-settings",
          [SCREENS.CREATE_COMMUNITY_POST.name]: "communities-create-community",
        },
      },
    },
  },
  [SCREENS.LOGIN.name]: "login",
  [SCREENS.REGISTER.name]: "register",
  [SCREENS.PASSWORD_RESET.name]: "password-reset",
  [SCREENS.NotFound.name]: "*",
};

// closefeed://community/64fb1525475bfd86969fc464
// closefeed://post-comments/64ee502b4d38e14acf311d6d
