import { Center, Text, Button } from "native-base";
import Colors from "constants/Colors";

const ErrorComponent = ({
  message,
  onRetry,
}: {
  message: string;
  onRetry?: () => void;
}) => {
  return (
    <Center
      flex={1}
      p={4}
      _dark={{
        bg: Colors.dark.background,
      }}
      _light={{
        bg: Colors.light.background,
      }}
    >
      <Text>{message}</Text>
      {onRetry ? <Button onPress={onRetry}>Try Again</Button> : null}
    </Center>
  );
};

export default ErrorComponent;
