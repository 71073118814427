import React, { useState } from "react";
import { Box, Heading, Spinner, useColorMode } from "native-base";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { NavigationProp, ParamListBase } from "@react-navigation/native";
import { getMyCommunities, getCommunityFeed } from "../../api/community";
import MyCommunities from "components/MyCommunities/MyCommunities";
import Colors from "constants/Colors";
import { minutesToMilliseconds } from "../ExploreCommunities/utils";
import PostList from "./components/PostList/PostList";
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";

interface CommunityFeedScreenProps {
  navigation: NavigationProp<ParamListBase>;
}

const COMMUNITY_FEED_QUERY_NAME = "communityFeed";

export const CommunityFeedScreen = ({
  navigation,
}: CommunityFeedScreenProps) => {
  const [refreshing, setRefreshing] = useState(false);

  const { colorMode } = useColorMode();
  const colorScheme = colorMode || "light";

  const { isPending: isCommunityLoading, data: communityData } = useQuery({
    queryKey: ["myCommunities"],
    queryFn: async () => {
      const { data } = await getMyCommunities({ pageParam: 1, limit: 5 });

      return data;
    },
    refetchInterval: 1000 * 60 * 30,
  });

  const {
    isPending,
    data,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    refetch: refetchCommunityFeed,
  } = useInfiniteQuery({
    queryKey: [COMMUNITY_FEED_QUERY_NAME],
    queryFn: async ({ pageParam = 1 }) => {
      const { data } = await getCommunityFeed({ pageParam });
      return data;
    },

    getNextPageParam: (lastPage) => {
      if (lastPage.page + 1 > lastPage.totalPages) {
        return null;
      }

      return lastPage.page + 1;
    },
    refetchInterval: minutesToMilliseconds(5),
    initialPageParam: 1,
  });

  const loadMore = () => {
    if (hasNextPage) {
      fetchNextPage();
    }
  };

  const handleRefresh = async () => {
    setRefreshing(true);
    await refetchCommunityFeed();
    setRefreshing(false);
  };

  return (
    <ErrorBoundary>
      <Box
        flex={1}
        safeAreaTop
        _dark={{ bg: Colors.dark.background }}
        _light={{ bg: Colors.light.background }}
      >
        {isPending ? (
          <Spinner />
        ) : (
          <>
            <Heading size="lg" mt={4} px={4}>
              Community Feed 🗺
            </Heading>

            <PostList
              listHeaderComponent={
                <MyCommunities
                  navigation={navigation}
                  data={communityData?.results}
                  isPending={isCommunityLoading}
                />
              }
              data={data?.pages.flatMap((page) => page.results)}
              isFetchingNextPage={isFetchingNextPage}
              loadMore={loadMore}
              refreshing={refreshing}
              handleRefresh={handleRefresh}
              colorScheme={colorScheme}
            />
          </>
        )}
      </Box>
    </ErrorBoundary>
  );
};

export default CommunityFeedScreen;
