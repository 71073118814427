import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";
import { Box } from "native-base";
import React from "react";
import { WebView } from "react-native-webview";

interface WebViewScreenProps {
  route: {
    params: {
      uri: string;
    };
  };
}

const WebViewScreen = ({ route }: WebViewScreenProps) => {
  const { uri } = route.params;
  return (
    <ErrorBoundary>
      <Box flex={1}>
        <WebView source={{ uri }} />
      </Box>
    </ErrorBoundary>
  );
};

export default WebViewScreen;
