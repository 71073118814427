import { Feather } from "@expo/vector-icons";
import { Row, Text } from "native-base";
import { TouchableWithoutFeedback } from "react-native";
import CommentCount from "../CommentCount/CommentCount";
import Votes from "../Votes/Votes";
import { IPost } from "types/index";
import Colors from "constants/Colors";

interface PostActionsProps {
  post: IPost;
  onUpVote: () => void;
  onDownVote: () => void;
  onFlagPress: () => void;
  isModerator: boolean;
  onModeratorAction: () => void;
  onCommentPress: () => void;
}

const PostActions = ({
  post,
  onUpVote,
  onDownVote,
  onFlagPress,
  isModerator,
  onModeratorAction,
  onCommentPress,
}: PostActionsProps) => {
  return (
    <Row justifyContent="space-between" alignItems="center" flex={1}>
      <Votes
        count={post.upVotes - post.downVotes || 0}
        onDownVotePress={onDownVote}
        onUpVotePress={onUpVote}
        userVote={post.userVote}
      />
      <CommentCount onPress={onCommentPress} count={post.numberOfComments} />
      {post.user && (
        <TouchableWithoutFeedback onPress={onFlagPress}>
          <Feather name="flag" size={18} color={Colors.light.secondary_text} />
        </TouchableWithoutFeedback>
      )}
      {isModerator && (
        <TouchableWithoutFeedback onPress={onModeratorAction}>
          <Row>
            <Feather
              name="shield"
              size={18}
              color={Colors.light.secondary_text}
            />
            <Text mx={2} color={Colors.light.secondary_text} fontWeight="500">
              Mod
            </Text>
          </Row>
        </TouchableWithoutFeedback>
      )}
    </Row>
  );
};

export default PostActions;
