import React, { useCallback, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import * as Location from "expo-location";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  Button,
  CheckIcon,
  Column,
  FormControl,
  Input,
  ScrollView,
  Select,
  Spinner,
  TextArea,
  WarningOutlineIcon,
  Image,
  Box,
} from "native-base";
import * as Sentry from "sentry-expo";
import Toast from "react-native-toast-message";
import { NavigationProp, ParamListBase } from "@react-navigation/native";
import { AxiosError } from "axios";
import { TouchableOpacity } from "react-native";
import { Ionicons } from "@expo/vector-icons";
import { ImagePickerAsset } from "expo-image-picker";
import * as ImagePicker from "expo-image-picker";

import { updateCommunity, uploadProfilePicture } from "../../api/community";
import SCREENS from "constants/Screen";
import {
  COMMUNITY_DETAIL_QUERY_NAME,
  useCommunityData,
} from "../Community/hooks/useCommunityData";
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";

const validationSchema = yup.object().shape({
  name: yup.string().min(1).max(30).required("Community name is required"),
  description: yup
    .string()
    .min(1)
    .max(500)
    .required("Community description is required"),
  visibilityScope: yup.string().required("Community visibility is required"),
});

interface CreateCommunityProps {
  navigation: NavigationProp<ParamListBase>;
  route: {
    params: {
      communityId: string;
    };
  };
}

type VisibilityScope = "local" | "city" | "country" | "global";

const EditCommunity = ({ navigation, route }: CreateCommunityProps) => {
  const { communityId } = route.params;
  const { communityDetail } = useCommunityData(communityId);
  const community = communityDetail?.data?.data;

  const [city, setCity] = useState<string | null>(
    community ? community.city : null
  );
  const [country, setCountry] = useState<string | null>(
    community ? community.country : null
  );
  const [countryCode, setCountryCode] = useState<string | null>(
    community ? community.countryCode : null
  );
  const [locationLoading, setLocationLoading] = useState(false);

  const queryClient = useQueryClient();

  const formik = useFormik<{
    name: string;
    description: string;
    visibilityScope: VisibilityScope;
    latitude?: number;
    longitude?: number;
    city?: string;
    country?: string;
    countryCode?: string;
  }>({
    initialValues: {
      name: community ? community.name : "",
      description: community ? community.description : "",
      visibilityScope: community ? community.visibilityScope : "global",
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      try {
        const requestData = { ...values };

        if (values.visibilityScope === "local") {
          const { status } = await Location.requestForegroundPermissionsAsync();
          if (status !== "granted") {
            Toast.show({
              type: "error",
              text1: "Permission Denied",
              text2: "Unable to access location data",
            });
            return;
          }

          const location = await Location.getCurrentPositionAsync({});
          requestData.latitude = location.coords.latitude;
          requestData.longitude = location.coords.longitude;
        } else if (values.visibilityScope === "city" && city) {
          requestData.city = city;
        } else if (
          values.visibilityScope === "country" &&
          country &&
          countryCode
        ) {
          requestData.country = country;
          requestData.countryCode = countryCode;
        }

        await mutation.mutateAsync({ data: requestData, communityId });
        // Do something on success
      } catch (error) {
        // Handle error
        Toast.show({
          type: "error",
          text1: error.response?.data.message || "Something went wrong",
        });
      }
    },
  });

  // Function to handle location request based on visibilityScope
  const handleVisibilityChange = useCallback(
    async (visibility: string) => {
      formik.setFieldValue("visibilityScope", visibility);

      if (
        visibility === "local" ||
        visibility === "city" ||
        visibility === "country"
      ) {
        // request location only if necessary
        setLocationLoading(true);
        (async () => {
          const { status } = await Location.requestForegroundPermissionsAsync();
          if (status !== "granted") {
            Toast.show({
              type: "error",
              text1: "Permission Denied",
              text2: "Unable to access location data",
            });
            return;
          }

          const location = await Location.getCurrentPositionAsync({});
          const geoInfo = await Location.reverseGeocodeAsync(location.coords);

          setCity(geoInfo[0]?.city);
          setCountry(geoInfo[0]?.country);
          setCountryCode(geoInfo[0]?.isoCountryCode);
        })();
        setLocationLoading(false);
      }
    },
    [formik]
  );

  const mutation = useMutation({
    mutationFn: updateCommunity,
    onError: (error: AxiosError) => {
      Sentry.Native.captureException(error);
      Toast.show({
        type: "error",
        text1: error.response?.data.message || "Something went wrong",
      });
    },
    onSuccess: (data) => {
      navigation.navigate(SCREENS.COMMUNITY.name, {
        communityId: data.data.id,
      });

      Toast.show({
        type: "success",
        text1: "Community updated",
      });
      navigation.goBack();
    },
  });

  const uploadProfileImage = useMutation({
    mutationFn: (image: ImagePickerAsset) =>
      uploadProfilePicture(communityId, image),
    onMutate: () => {
      Toast.show({
        type: "info",
        text1: "Uploading profile picture",
      });
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({
        queryKey: [COMMUNITY_DETAIL_QUERY_NAME, communityId],
      });

      Toast.show({
        type: "success",
        text1: "Profile picture updated",
      });
    },
    onError: (error: AxiosError) => {
      Toast.show({
        type: "error",
        text1: error.response?.data.message || "Failed to upload image",
      });
    },
  });

  const pickProfileImage = async () => {
    // No permissions request is necessary for launching the image library
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
    });

    if (!result.canceled) {
      uploadProfileImage.mutate(result.assets[0]);
    }
  };

  return (
    <ErrorBoundary>
      <ScrollView flex={1} px={4}>
        <Column mb={3} space={2}>
          <TouchableOpacity
            onPress={pickProfileImage}
            style={{
              position: "relative",
              width: "100%",
              height: 140,
            }}
          >
            <Image
              source={{ uri: community.profileImage }}
              alt={community.name}
              h={140}
              w="full"
            />
            <Box
              position="absolute"
              bg="black"
              opacity={0.4}
              w="100%"
              h="100%"
              alignItems="center"
              justifyContent="center"
              top={0}
              left={0}
            >
              <Ionicons name="md-camera" size={30} color="white" />
            </Box>
          </TouchableOpacity>
          <FormControl isInvalid={!!formik.errors.name && formik.touched.name}>
            <FormControl.Label>Community name</FormControl.Label>
            <Input
              value={formik.values.name}
              onChangeText={formik.handleChange("name")}
              onBlur={formik.handleBlur("name")}
            />
            <FormControl.ErrorMessage
              leftIcon={<WarningOutlineIcon size="xs" />}
            >
              {formik.errors.name}
            </FormControl.ErrorMessage>
          </FormControl>
          <FormControl
            isInvalid={
              !!formik.errors.description && formik.touched.description
            }
          >
            <FormControl.Label>Community description</FormControl.Label>
            <TextArea
              autoCompleteType
              value={formik.values.description}
              onChangeText={formik.handleChange("description")}
              onBlur={formik.handleBlur("description")}
            />
            <FormControl.ErrorMessage
              leftIcon={<WarningOutlineIcon size="xs" />}
            >
              {formik.errors.description}
            </FormControl.ErrorMessage>
          </FormControl>
          <FormControl
            isInvalid={
              !!formik.errors.visibilityScope && formik.touched.visibilityScope
            }
          >
            <FormControl.Label>Community Visibility</FormControl.Label>
            <Select
              selectedValue={formik.values.visibilityScope}
              minWidth={200}
              accessibilityLabel="Visibility"
              placeholder="Select Visibility"
              onValueChange={(itemValue) => handleVisibilityChange(itemValue)}
              _selectedItem={{
                bg: "teal.600",
                endIcon: <CheckIcon size={4} />,
              }}
            >
              <Select.Item
                label="Local - visible around your location"
                value="local"
              />
              <Select.Item
                label="City - visible to people in your city"
                value="city"
              />
              <Select.Item
                label="Country - visible to people in your country"
                value="country"
              />
              <Select.Item
                label="Global - visible to everyone"
                value="global"
              />
            </Select>
            <FormControl.ErrorMessage
              leftIcon={<WarningOutlineIcon size="xs" />}
            >
              {formik.errors.visibilityScope}
            </FormControl.ErrorMessage>
          </FormControl>
        </Column>
        {mutation.isPending || locationLoading ? (
          <Spinner />
        ) : (
          <Button
            onPress={() => formik.handleSubmit()}
            disabled={!formik.dirty || !formik.isValid || mutation.isPending}
          >
            Update Community
          </Button>
        )}
      </ScrollView>
    </ErrorBoundary>
  );
};

export default EditCommunity;
