import { Badge, Box, Column, Row, Text } from "native-base";
import { ICommunityMember } from "types/index";
import Colors from "constants/Colors";

interface MemberCard {
  member: ICommunityMember;
}

const MemberCard = ({ member }: MemberCard) => {
  if (member.user.deleted) {
    return (
      <Box p={4}>
        <Text>User deleted</Text>
      </Box>
    );
  }

  return (
    <Row alignItems="center" space={2} px={4} py={2}>
      <Box
        h={7}
        w={7}
        rounded="full"
        alignItems="center"
        justifyContent="center"
        borderWidth={1}
        borderColor={Colors.light.secondary_text}
      >
        <Text fontSize="xs">{member.user.avatarChar}</Text>
      </Box>
      <Column>
        <Text
          fontWeight="500"
          color="trueGray.700"
          fontSize="xs"
          _dark={{
            color: "trueGray.300",
          }}
        >
          {member.user.displayName}{" "}
          {member.role === "moderator" ? (
            <Badge colorScheme="info">MOD</Badge>
          ) : null}
        </Text>
        <Text color="trueGray.500" fontSize="xs">
          @{member.user.username}
        </Text>
      </Column>
    </Row>
  );
};

export default MemberCard;
