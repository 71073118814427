import axios from "axios";
import * as Location from "expo-location";
import * as Sentry from "sentry-expo";

// {
//     ip: '102.89.23.86',
//     city: 'Lagos',
//     region: 'Lagos',
//     country: 'NG',
//     loc: '6.4541,3.3947',
//     org: 'AS29465 MTN NIGERIA Communication limited',
//     timezone: 'Africa/Lagos',
//     readme: 'https://ipinfo.io/missingauth'
//   }
// interface IpLocation {
//   ip: string;
//   city: string;
//   region: string;
//   country: string;
//   loc: string;
//   org: string;
//   timezone: string;
//   readme: string;
// }

const getLocationFromIP = async () => {
  const getIPAddress = async () => {
    try {
      const response = await axios.get("https://api.ipify.org?format=json");
      return response.data.ip;
    } catch (error) {
      Sentry.Native.captureException(error);
      return null;
    }
  };

  const getUserLocation = async () => {
    const userIP = await getIPAddress();
    if (userIP) {
      try {
        const locationResponse = await axios.get(
          `https://ipinfo.io/${userIP}/geo`
        );
        return locationResponse.data;
      } catch (error) {
        Sentry.Native.captureException(error);
        return null;
      }
    }
  };

  const response = await getUserLocation();
  return response;
};

export const fetchLocationFromIP = async () => {
  try {
    const ipLocation = await getLocationFromIP();
    if (ipLocation && ipLocation.loc) {
      const [latitude, longitude] = ipLocation.loc
        .split(",")
        .map((coord: string) => parseFloat(coord));
      return {
        city: ipLocation.city,
        countryCode: ipLocation.country,
        latitude,
        longitude,
      };
    }
    return null;
  } catch (error) {
    Sentry.Native.captureException(error);
    return null;
  }
};

const fetchLocationFromDevice = async () => {
  try {
    const { status } = await Location.requestForegroundPermissionsAsync();
    if (status !== "granted") {
      throw new Error("Location permission not granted");
    }
    const { coords } = await Location.getCurrentPositionAsync({});
    return {
      latitude: coords.latitude,
      longitude: coords.longitude,
    };
  } catch (error) {
    Sentry.Native.captureException(error);
    return null;
  }
};

export interface GeoLocation {
  latitude: number | null;
  longitude: number | null;
  city: string | null;
  countryCode: string | null;
}

// Revised fetchLocation function
export const fetchLocation = async () => {
  let locationData: GeoLocation = {
    latitude: null,
    longitude: null,
    city: null,
    countryCode: null,
  };
  const deviceLocationData = await fetchLocationFromDevice();

  if (deviceLocationData) {
    locationData = {
      latitude: deviceLocationData.latitude,
      longitude: deviceLocationData.longitude,
      city: null, // default to null
      countryCode: null, // default to null
    };

    // Attempt to enrich location data with city and country code from IP if device location is found
    const ipLocationData = await fetchLocationFromIP();
    if (ipLocationData && ipLocationData.city && ipLocationData.countryCode) {
      locationData.city = ipLocationData.city;
      locationData.countryCode = ipLocationData.countryCode;
    } else {
      // Try to use reverse geocoding to find city and country code if IP-based lookup fails
      try {
        const geoInfo = await Location.reverseGeocodeAsync(deviceLocationData);
        if (geoInfo.length > 0) {
          locationData.city = geoInfo[0]?.city || null;
          locationData.countryCode = geoInfo[0]?.isoCountryCode || null;
        } else {
          locationData.city = null;
          locationData.countryCode = null;
        }
      } catch (error) {
        Sentry.Native.captureException(error);
      }
    }
  }

  return locationData;
};
