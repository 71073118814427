import axios, { AxiosRequestConfig } from "axios";
// import Constants from "expo-constants";
import { auth } from "../config/firebase";

// const { expoConfig } = Constants;
// const LOCAL_API_URL = `http://${expoConfig?.hostUri
//   ?.split(":")
//   .shift()}:3000/v1`;

const createToken = async () => {
  const token = await auth.currentUser?.getIdToken();

  const payloadHeader = {
    headers: {
      ...(token && { Authorization: `Bearer ${token}` }),
    },
  };
  return payloadHeader;
};

const request = async (props: AxiosRequestConfig) => {
  const header = await createToken();

  return axios({
    baseURL: "https://7gmf3eq7z8.execute-api.eu-west-2.amazonaws.com/v1",
    headers: { ...header.headers, ...props.headers },
    ...props,
  }).catch((error) => {
    // if 401, logout
    if (error.response?.status === 401) {
      auth().signOut();
    }
  });
};

export default request;
