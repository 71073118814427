import React from "react";
import * as Sentry from "sentry-expo";
import { auth, onAuthStateChanged } from "../config/firebase";

import { getCurrentUser } from "../api/user";
import { useUserContext } from "../context/UserContext";

export function useAuthentication() {
  const [firebaseUser, setFirebaseUser] = React.useState<any>("init");
  const { setUser } = useUserContext();

  const fetchUser = async () => {
    try {
      const { data } = await getCurrentUser();
      return data;
    } catch (error) {
      Sentry.Native.captureException(error);
    }
  };

  React.useEffect(() => {
    const unsubscribeFromAuthStatusChanged = onAuthStateChanged(
      auth,
      async (user) => {
        if (user) {
          // User is signed in, see docs for a list of available properties
          // https://firebase.google.com/docs/reference/js/firebase.User

          // Fetch user data from the API
          const apiUserData = await fetchUser();

          // Merge Firebase user data with API user data
          const combinedUserData = {
            ...apiUserData,
            firebaseUid: user.uid,
            email: user.email,
            emailVerified: user.emailVerified,
          };

          setUser(combinedUserData);
          setFirebaseUser(user);
        } else {
          // User is signed out
          setFirebaseUser(null);
          setUser(null);
        }
      }
    );

    return unsubscribeFromAuthStatusChanged;
  }, []);

  return { user: firebaseUser };
}
