import * as Device from "expo-device";
import * as Notifications from "expo-notifications";
import { Platform } from "react-native";
import Constants from "expo-constants";
import request from "../utils/request";
import { getCurrentUser, updateUser } from "./user";
import {
  auth,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
} from "../config/firebase";

export const registerForPushNotificationsAsync = async () => {
  let token;

  if (Platform.OS === "android") {
    await Notifications.setNotificationChannelAsync("default", {
      name: "default",
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
      lightColor: "#FF231F7C",
    });
  }

  if (Device.isDevice) {
    const { status: existingStatus } =
      await Notifications.getPermissionsAsync();
    let finalStatus = existingStatus;
    if (existingStatus !== "granted") {
      const { status } = await Notifications.requestPermissionsAsync();
      finalStatus = status;
    }
    if (finalStatus !== "granted") {
      alert("Failed to get push token for push notification!");
      return;
    }
    token = (
      await Notifications.getExpoPushTokenAsync({
        projectId: Constants.expoConfig?.extra?.eas.projectId,
      })
    ).data;
  } else {
    alert("Must use physical device for Push Notifications");
  }

  return token;
};

export const updateUserPushToken = async () => {
  // Register for push notifications
  const expoPushToken = await registerForPushNotificationsAsync();
  if (!expoPushToken) {
    throw new Error("No expo push token");
  }
  await updateUser({
    expoPushToken,
  });

  return expoPushToken;
};

export const postLogin = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}) => {
  const { user } = await signInWithEmailAndPassword(auth, email, password);
  const userData = await getCurrentUser();

  // Register for push notifications
  await updateUserPushToken();

  return { ...userData.data, uid: user?.uid };
};

export const postRegister = async (values: {
  displayName: string;
  email: string;
  password: string;
}) => {
  const response = await request({
    method: "post",
    url: "/auth/register",
    data: values,
  });
  await postLogin({
    email: values.email,
    password: values.password,
  });

  // Register for push notifications
  await updateUserPushToken();

  return response.data.user;
};

export const logout = async () => {
  await auth.signOut();
};

export const passwordReset = (email: string) => {
  // return auth().sendPasswordResetEmail(email);
  return sendPasswordResetEmail(auth, email);
};
