// hooks/usePostComment.ts
import Toast from "react-native-toast-message";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import * as Sentry from "sentry-expo";
import { postComment } from "../../../api/comment";
import { AxiosError } from "axios";

export const usePostComment = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: postComment,
    onSuccess: () => {
      Toast.show({
        type: "success",
        text1: "Reply sent",
      });
      queryClient.invalidateQueries({ queryKey: ["comments"] });
    },
    onError: (error: AxiosError) => {
      Sentry.Native.captureException(error);
      Toast.show({
        type: "error",
        text1: error.response?.data.message || "Something went wrong",
      });
    },
  });
};
