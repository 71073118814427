import * as Location from "expo-location";
import * as ImagePicker from "expo-image-picker";

export const getForegroundLocationPermission = () => {
  return Location.getForegroundPermissionsAsync();
};

export const requestLocationPermission = () => {
  return Location.requestForegroundPermissionsAsync();
};

export const getCurrentLocationAsync = async () => {
  await requestLocationPermission();
  return Location.getCurrentPositionAsync();
};

export const getLastKnownPositionAsync = async () => {
  await requestLocationPermission();
  return Location.getLastKnownPositionAsync();
};

export const getReverseGeocodeAsync = async ({
  longitude,
  latitude,
}: {
  longitude: number;
  latitude: number;
}) => {
  await requestLocationPermission();
  return Location.reverseGeocodeAsync({
    longitude,
    latitude,
  });
};

export const launchImageLibraryAsync = async (
  opts: ImagePicker.ImagePickerOptions
) => {
  const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
  if (status !== "granted") {
    alert("Sorry, we need camera roll permissions to make this work!");
  }
  return ImagePicker.launchImageLibraryAsync(opts);
};
