import React, { useState } from "react";
import { Formik, FormikErrors, FormikTouched } from "formik";
import { useMutation } from "@tanstack/react-query";
import { NavigationProp, ParamListBase } from "@react-navigation/native";
import * as Yup from "yup";
import * as Sentry from "sentry-expo";
import {
  Button,
  Heading,
  VStack,
  Text,
  Spinner,
  ScrollView,
  Box,
  FormControl,
  WarningOutlineIcon,
  Input,
  useColorModeValue,
} from "native-base";

import SCREENS from "constants/Screen";
import { postLogin } from "../../api/auth";
import Colors from "constants/Colors";
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";

const formIsDirty = (
  touched: FormikTouched<{ email: string; password: string }>
) => Object.values(touched).some((value) => value);

const renderErrorMessage = (
  errors: FormikErrors<{ email: string; password: string }>,
  touched: FormikTouched<{ email: string; password: string }>,
  fieldName: keyof FormikErrors<{ email: string; password: string }>
) => {
  return errors[fieldName] && touched[fieldName] ? (
    <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
      {errors[fieldName]}
    </FormControl.ErrorMessage>
  ) : null;
};

export interface LoginScreenProps {
  navigation: NavigationProp<ParamListBase>;
}

const LoginSchema = Yup.object({
  email: Yup.string().email("Invalid email address").required("Required"),
  password: Yup.string()
    .required("Required")
    .min(6, "Must be 6 characters or more"),
});

const LoginScreen = ({ navigation }: LoginScreenProps) => {
  const [error, setError] = useState("");

  const bg = useColorModeValue(Colors.light.background, Colors.dark.background);

  const mutation = useMutation({
    mutationFn: postLogin,
    onSuccess: async () => {},
    onError: async (error: unknown) => {
      Sentry.Native.captureException(error);
      switch ((error as { code: string }).code) {
        case "auth/wrong-password":
        case "auth/invalid-email":
          setError("You have entered an invalid email or password");
          break;
        case "auth/user-disabled":
          setError("Your account is suspended. Contact support@closefeed.com");
          break;
        case "auth/user-not-found":
          setError("No user found with this email");
          break;
        default:
          setError(
            (error as { message: string }).message || "Something went wrong"
          );
      }
    },
  });

  return (
    <ErrorBoundary>
      <Box flex={1} bg={bg}>
        <ScrollView p={5}>
          <VStack space={1} mb={10}>
            <Heading size="xl">Login</Heading>
            <Text fontSize="md" color="dark.400">
              Use your email and password to login
            </Text>
          </VStack>

          <Formik
            initialValues={{ email: "", password: "", displayName: "" }}
            onSubmit={(values) => mutation.mutate(values)}
            validationSchema={LoginSchema}
          >
            {({
              handleChange,
              handleBlur,
              handleSubmit,
              values,
              errors,
              touched,
              isValid,
            }) => (
              <VStack space={3}>
                <FormControl
                  isInvalid={errors.email && touched.email ? true : false}
                >
                  <FormControl.Label>Email</FormControl.Label>
                  <Input
                    size="xl"
                    placeholder="email@example.com"
                    autoCorrect={false}
                    keyboardType="email-address"
                    onChangeText={handleChange("email")}
                    onBlur={handleBlur("email")}
                    value={values.email}
                  />
                  {renderErrorMessage(errors, touched, "email")}
                </FormControl>
                <FormControl
                  isInvalid={errors.password && touched.password ? true : false}
                >
                  <FormControl.Label>Password</FormControl.Label>
                  <Input
                    size="xl"
                    placeholder="**********"
                    secureTextEntry
                    onChangeText={handleChange("password")}
                    onBlur={handleBlur("password")}
                    value={values.password}
                  />
                  {renderErrorMessage(errors, touched, "password")}
                </FormControl>

                <Text
                  onPress={() =>
                    navigation.navigate(SCREENS.PASSWORD_RESET.name)
                  }
                >
                  Forgot password?
                </Text>
                {error ? <Text color="danger.500">{error}</Text> : null}
                <Box>
                  {mutation.isPending ? (
                    <Spinner color="muted.500" />
                  ) : (
                    <VStack space={3}>
                      <Button
                        size="lg"
                        isDisabled={!(isValid && formIsDirty(touched))}
                        onPress={() => handleSubmit()}
                      >
                        Login
                      </Button>
                      <Button
                        size="lg"
                        variant="ghost"
                        onPress={() =>
                          navigation.navigate(SCREENS.REGISTER.name)
                        }
                      >
                        Get Started
                      </Button>
                    </VStack>
                  )}
                </Box>
              </VStack>
            )}
          </Formik>
        </ScrollView>
      </Box>
    </ErrorBoundary>
  );
};

export default LoginScreen;
