import { Feather } from "@expo/vector-icons";
import React, { useState, useCallback } from "react";
import * as ImagePicker from "expo-image-picker";
import * as Yup from "yup";
import { TouchableOpacity } from "react-native";
import {
  Box,
  Button,
  Input,
  Row,
  VStack,
  Text,
  ScrollView,
  Icon,
  Column,
  Progress,
  FormControl,
  WarningOutlineIcon,
  TextArea,
  Image,
} from "native-base";
import * as Sentry from "sentry-expo";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { NavigationProp, ParamListBase } from "@react-navigation/native";
import Toast from "react-native-toast-message";
import { ResizeMode, Video } from "expo-av";
import { AxiosError } from "axios";
import {
  Video as VideoCompressor,
  Image as ImageCompressor,
} from "react-native-compressor";
import { Formik, FormikErrors, FormikTouched } from "formik";

import Colors from "constants/Colors";
import { launchImageLibraryAsync } from "../../utils/expoSdk";
import { createPost } from "../../api/post";
import { ICommunity } from "types/index";
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";

// add limit to backend
const maxWordCount = 2000;

interface CreatePostProps {
  navigation: NavigationProp<ParamListBase>;
  route: {
    params: {
      community: ICommunity;
    };
  };
}

type ImageObject = {
  uri: string;
};

const CreatePostSchema = Yup.object({
  title: Yup.string().max(100),
  text: Yup.string().required("Required").max(maxWordCount),
});

const renderErrorMessage = (
  errors: FormikErrors<{ title: string; text: string }>,
  touched: FormikTouched<{ title: string; text: string }>,
  fieldName: keyof FormikErrors<{ title: string; text: string }>
) => {
  return errors[fieldName] && touched[fieldName] ? (
    <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
      {errors[fieldName]}
    </FormControl.ErrorMessage>
  ) : null;
};

const CreateCommunityPost = ({ navigation, route }: CreatePostProps) => {
  const [image, setImage] = useState<ImageObject[]>([]);
  const [video, setVideo] = useState<ImageObject[]>([]);
  const [videoProgress, setVideoProgress] = useState(0);

  const community = route.params.community;

  const videoRef = React.useRef(null);

  const handlePost = useCallback(
    (values: { title: string; text: string }) => {
      const payload = {
        ...values,
        media: [...image, ...video],
        community: community.id,
      };

      mutation.mutate(payload);
      navigation.goBack();
    },
    [image, video, community.id, navigation]
  );

  // Access the client
  const queryClient = useQueryClient();

  // Mutations
  const mutation = useMutation({
    mutationFn: createPost,
    onMutate: () => {
      Toast.show({
        type: "success",
        text1: "Post sent",
      });
    },
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: ["communityPosts"] });
    },
    onError: (error: AxiosError) => {
      Sentry.Native.captureException(error);
      Toast.show({
        type: "error",
        text1: error.response?.data.message || "Something went wrong",
      });
    },
  });

  const pickImage = async () => {
    if (video.length > 0) {
      Toast.show({
        type: "error",
        text1: "You can't add images when a video is selected.",
      });
      return;
    }

    if (image.length > 3) {
      Toast.show({
        type: "error",
        text1: "Maximum number of photos reached",
      });
      return;
    }
    try {
      const result = await launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
      });

      if (!result.canceled && result.assets && result.assets.length > 0) {
        const compressedFileUrl = await ImageCompressor.compress(
          result.assets[0].uri
        );

        setImage([...image, { uri: compressedFileUrl }]);
      }
    } catch (error) {
      Sentry.Native.captureException(error);
    }
  };

  const pickVideo = async () => {
    if (image.length > 0) {
      alert("You can't add a video when images are selected.");
      return;
    }

    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Videos,
      allowsEditing: true,
    });

    setVideoProgress(1);

    if (!result.canceled && result.assets && result.assets.length > 0) {
      try {
        const compressedFileUrl = await VideoCompressor.compress(
          result.assets[0].uri,
          {
            // Specify any compression options here
          },
          (progress) => {
            setVideoProgress(progress * 100);
          }
        );

        setVideo([{ uri: compressedFileUrl }]);
        setVideoProgress(0);
      } catch (error) {
        setVideoProgress(0);
        Sentry.Native.captureException(error);
      }
    }
  };

  const renderImages = useCallback(() => {
    const allImages = image.map((each: { uri: string }, index: number) => {
      return (
        <Box key={index} mr={3}>
          <Image
            h={100}
            w={100}
            rounded="sm"
            source={{ uri: each.uri }}
            alt="post image"
          />
          <Button
            onPress={() => setImage(image.filter((_, i) => i !== index))}
            size="sm"
            variant="ghost"
          >
            remove
          </Button>
        </Box>
      );
    });
    return <ScrollView horizontal>{allImages}</ScrollView>;
  }, [image]);

  return (
    <ErrorBoundary>
      <ScrollView px={4} contentContainerStyle={{ flex: 1 }}>
        <VStack space={3} mb={3} py={4}>
          <Text>
            Share with
            {
              <>
                <Text fontFamily="airbnb-cereal-medium"> {community.name}</Text>
              </>
            }
          </Text>

          <Formik
            initialValues={{ title: "", text: "" }}
            onSubmit={handlePost}
            validationSchema={CreatePostSchema}
          >
            {({
              handleChange,
              handleBlur,
              values,
              errors,
              touched,
              isValid,
              dirty,
              handleSubmit,
            }) => (
              <VStack space={3}>
                <FormControl
                  isInvalid={errors.title && touched.title ? true : false}
                >
                  <Input
                    placeholder="Title (Optional)"
                    size="xl"
                    onChangeText={handleChange("title")}
                    onBlur={handleBlur("title")}
                    value={values.title}
                    fontFamily="airbnb-cereal-medium"
                  />
                  {renderErrorMessage(errors, touched, "title")}
                </FormControl>
                <FormControl
                  isInvalid={errors.text && touched.text ? true : false}
                >
                  <TextArea
                    placeholder="body text"
                    autoCompleteType="off"
                    size="md"
                    onChangeText={handleChange("text")}
                    onBlur={handleBlur("text")}
                    value={values.text}
                  />
                  {renderErrorMessage(errors, touched, "text")}
                </FormControl>

                {React.useLayoutEffect(() => {
                  navigation.setOptions({
                    headerRight: () => (
                      <Box w={20} mr={3}>
                        <Button
                          size="sm"
                          variant="ghost"
                          isDisabled={!(isValid && dirty)}
                          onPress={() => handleSubmit()}
                        >
                          Post
                        </Button>
                      </Box>
                    ),
                  });
                }, [navigation, isValid, dirty, handleSubmit])}
              </VStack>
            )}
          </Formik>

          <Row space={3} alignItems="center">
            <TouchableOpacity
              onPress={pickImage}
              hitSlop={{ top: 5, right: 5, bottom: 5 }}
            >
              <Icon
                as={Feather}
                size="md"
                color={Colors.light.secondary_text}
                name="image"
              />
            </TouchableOpacity>
            <TouchableOpacity
              onPress={pickVideo}
              hitSlop={{ top: 5, right: 5, bottom: 5 }}
            >
              <Icon
                as={Feather}
                size="md"
                color={Colors.light.secondary_text}
                name="video"
              />
            </TouchableOpacity>
          </Row>
        </VStack>
        {image.length > 0 && renderImages()}

        {videoProgress > 0 ? <Progress value={videoProgress} /> : null}
        {video.length > 0 && (
          <Column space={2}>
            <Video
              ref={videoRef}
              style={{ width: "100%", height: 300 }}
              source={{
                uri: video[0].uri,
              }}
              useNativeControls
              resizeMode={ResizeMode.CONTAIN}
            />
            <Button
              onPress={() => {
                setVideo([]);
                setVideoProgress(0);
              }}
              size="sm"
              variant="ghost"
            >
              remove
            </Button>
          </Column>
        )}
      </ScrollView>
    </ErrorBoundary>
  );
};

export default CreateCommunityPost;
