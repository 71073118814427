import { Heading, Text, Column, Row, Button } from "native-base";

import * as RootNavigation from "../../../../navigation/RootNavigation";
import SCREENS from "constants/Screen";

const EmptyList = () => {
  return (
    <Column space={2} px={4}>
      <Heading>Welcome to Closefeed!</Heading>
      <Text>
        Your feed is quiet for now. Tap 'Explore' to find and join communities
        or 'Create' to start your own.
      </Text>

      <Row space={3}>
        <Button
          variant="solid"
          onPress={async () => {
            RootNavigation.navigate(SCREENS.COMMUNITIES.name, {});
          }}
        >
          Explore
        </Button>
        <Button
          variant="outline"
          onPress={async () => {
            RootNavigation.navigate(SCREENS.CREATE_COMMUNITY.name, {});
          }}
        >
          Create
        </Button>
      </Row>
    </Column>
  );
};

export default EmptyList;
