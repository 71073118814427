import React, { useState } from "react";
import { RefreshControl } from "react-native";
import { useColorMode, FlatList, Spinner, Center } from "native-base";
import { useInfiniteQuery } from "@tanstack/react-query";
import { getCommunities } from "../../../api/community";
import { ICommunity } from "types/index";
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";

import Colors from "constants/Colors";
import CommunityItem from "components/CommunityItem/CommunityItem";
import { NavigationProp, ParamListBase } from "@react-navigation/native";
import SCREENS from "constants/Screen";
import EmptyList from "components/EmptyList/EmptyList";

interface GlobalCommunitiesProps {
  navigation: NavigationProp<ParamListBase>;
}

const GlobalCommunities = ({ navigation }: GlobalCommunitiesProps) => {
  const [refreshing, setRefreshing] = useState(false);
  const { colorMode } = useColorMode();
  const colorScheme = colorMode || "light";

  const {
    isPending,
    data,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    refetch,
  } = useInfiniteQuery({
    queryKey: ["communities", "global"],
    queryFn: async ({ pageParam = 1 }) => {
      const { data } = await getCommunities({
        visibilityScope: "global",
        pageParam,
      });
      return data;
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      if (lastPage.page + 1 > lastPage.totalPages) {
        return null;
      }

      return lastPage.page + 1;
    },
  });

  const handleRefresh = async () => {
    setRefreshing(true);
    await refetch();
    setRefreshing(false);
  };

  const loadMore = () => {
    if (hasNextPage) {
      fetchNextPage();
    }
  };

  const renderCommunity = ({ item }: { item: ICommunity }) => {
    return (
      <CommunityItem
        community={item}
        onPress={() =>
          navigation.navigate(SCREENS.COMMUNITY.name, {
            communityId: item.id,
          })
        }
      />
    );
  };

  if (isPending)
    <Center
      flex={1}
      _dark={{
        bg: Colors.dark.background,
      }}
      _light={{
        bg: Colors.light.background,
      }}
    >
      <Spinner />
    </Center>;

  return (
    <ErrorBoundary>
      <FlatList
        p={4}
        data={data?.pages.flatMap((page) => page.results)}
        renderItem={renderCommunity}
        keyExtractor={(item, index) => item.id || index.toString()}
        // ItemSeparatorComponent={Separator}
        ListEmptyComponent={<EmptyList text="No communities" />}
        onEndReachedThreshold={0.2}
        ListFooterComponent={isFetchingNextPage ? <Spinner /> : null}
        onEndReached={loadMore}
        refreshControl={
          <RefreshControl
            refreshing={refreshing}
            onRefresh={handleRefresh}
            tintColor={Colors[colorScheme].text}
          />
        }
      />
    </ErrorBoundary>
  );
};

export default GlobalCommunities;
