import React from "react";
import { StyleSheet } from "react-native";
import { Box, Text, Row, Column, useColorMode } from "native-base";
import relativeTime from "dayjs/plugin/relativeTime";
import ParsedText from "react-native-parsed-text";
import dayjs from "dayjs";
dayjs.extend(relativeTime);
import { IComment } from "types/index";
import Colors from "constants/Colors";
import SCREENS from "constants/Screen";
import * as RootNavigation from "../../navigation/RootNavigation";

interface CommentProps {
  comment: IComment;
}

const Comment = ({ comment }: CommentProps) => {
  const { colorMode } = useColorMode();
  const colorScheme = colorMode || "light";

  const onUrlPress = (uri: string) => {
    RootNavigation.navigate(SCREENS.WEB_VIEW.name, {
      uri,
    });
  };

  return (
    <Column space={2} py={2} px={4}>
      <Row justifyContent="space-between" alignItems="center">
        <Row space={2} alignItems="center">
          <Box
            h={7}
            w={7}
            rounded="full"
            alignItems="center"
            justifyContent="center"
            borderWidth={1}
            borderColor={Colors.light.secondary_text}
          >
            <Text fontSize="xs">{comment.user.avatarChar}</Text>
          </Box>

          <Text
            fontWeight="500"
            color="trueGray.700"
            fontSize="xs"
            _dark={{
              color: "trueGray.300",
            }}
          >
            {comment.user.displayName}
          </Text>
        </Row>
        <Text
          color="trueGray.500"
          fontSize="xs"
          _dark={{
            color: "trueGray.300",
          }}
        >
          {dayjs(comment.createdAt).fromNow()}
        </Text>
      </Row>

      <ParsedText
        style={[styles.text, { color: Colors[colorScheme].text }]}
        parse={[
          {
            type: "url",
            style: [styles.url, { color: Colors[colorScheme].danger }],
            onPress: onUrlPress,
          },
          {
            pattern: /#(\w+)/,
            style: [
              styles.hashTag,
              {
                color: Colors[colorScheme].danger,
              },
            ],
          },
        ]}
        childrenProps={{ allowFontScaling: false }}
      >
        {comment.text}
      </ParsedText>
    </Column>
  );
};

export default Comment;

const styles = StyleSheet.create({
  text: {
    fontFamily: "airbnb-cereal-book",
    letterSpacing: 0.5,
    lineHeight: 20,
    fontSize: 15,
  },
  hashTag: {
    color: "#00f",
  },
  url: {
    textDecorationLine: "underline",
  },
});
