import * as Location from "expo-location";

export const areLocationsSimilar = (
  loc1?: Location.LocationObjectCoords,
  loc2?: Location.LocationObjectCoords,
  precision = 3
) => {
  const round = (num: number) => Number(num.toFixed(precision));
  return (
    loc1 &&
    loc2 &&
    round(loc1.latitude) === round(loc2.latitude) &&
    round(loc1.longitude) === round(loc2.longitude)
  );
};

export const minutesToMilliseconds = (minutes: number) => minutes * 60 * 1000;

export const hoursToMilliseconds = (hours: number) =>
  minutesToMilliseconds(hours * 60);
