import { useActionSheet } from "@expo/react-native-action-sheet";
import { useQueryClient } from "@tanstack/react-query";
import Toast from "react-native-toast-message";
import { useState } from "react";

import { blockUser } from "../../api/user";
import { reportPost } from "../../api/post";
import { assignModerator, banMember, rejectPost } from "../../api/community";

export const usePostActions = (queryName: string) => {
  const { showActionSheetWithOptions } = useActionSheet();
  const queryClient = useQueryClient();
  const [, setOptionOpen] = useState(false);

  const handleBlockUser = async (userId: string) => {
    await blockUser(userId);
    queryClient.invalidateQueries({ queryKey: [queryName] });

    Toast.show({ type: "success", text1: "User blocked" });
    setOptionOpen(false);
  };

  const handleReportPost = async (postId: string) => {
    await reportPost(postId);

    Toast.show({
      type: "success",
      text1: "Post reported. We will review within 24 hours.",
    });
    setOptionOpen(false);
    queryClient.invalidateQueries({ queryKey: [queryName] });
  };

  const handleBanUser = async (communityId: string, userId: string) => {
    await banMember(communityId, userId);

    queryClient.invalidateQueries({ queryKey: [queryName] });

    Toast.show({ type: "success", text1: "User banned" });
    setOptionOpen(false);
  };

  const handleRejectPost = async (communityId: string, postId: string) => {
    await rejectPost(communityId, postId);

    queryClient.invalidateQueries({ queryKey: [queryName] });

    Toast.show({ type: "success", text1: "Post rejected" });
    setOptionOpen(false);
  };

  const handleAssignMod = async (communityId: string, userId: string) => {
    await assignModerator(communityId, userId);

    Toast.show({ type: "success", text1: "Added as mod" });
    setOptionOpen(false);
  };

  const onOpenActionSheet = ({
    userId,
    displayName,
    postId,
  }: {
    userId: string;
    displayName: string;
    postId: string;
  }) => {
    const options = [`Block ${displayName}`, "Report Post", "Cancel"];
    const destructiveButtonIndex = [0, 1];
    const cancelButtonIndex = 2;

    showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
        destructiveButtonIndex,
      },
      (buttonIndex) => {
        switch (buttonIndex) {
          case 0:
            handleBlockUser(userId);
            break;
          case 1:
            handleReportPost(postId);
            break;
        }
      }
    );
  };

  const onOpenModActionSheet = ({
    userId,
    displayName,
    postId,
    communityId,
  }: {
    userId: string;
    displayName: string;
    postId: string;
    communityId: string;
  }) => {
    const options = [
      `Ban ${displayName}`,
      "Remove post",
      "Assign moderator",
      "Cancel",
    ];
    const destructiveButtonIndex = [0, 1];
    const cancelButtonIndex = 3;

    showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex,
        destructiveButtonIndex,
      },
      (buttonIndex) => {
        switch (buttonIndex) {
          case 0:
            handleBanUser(communityId, userId);
            break;
          case 1:
            handleRejectPost(communityId, postId);
            break;
          case 2:
            handleAssignMod(communityId, userId);
            break;
        }
      }
    );
  };

  return { onOpenActionSheet, onOpenModActionSheet };
};
