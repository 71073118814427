import React, { useState } from "react";
import * as Sentry from "sentry-expo";
import { useInfiniteQuery } from "@tanstack/react-query";
import { Spinner, FlatList, Center, useColorMode } from "native-base";
import { NavigationProp, ParamListBase } from "@react-navigation/native";
import { RefreshControl, TouchableOpacity } from "react-native";
import Separator from "components/Separator/Separator";
import EmptyList from "components/EmptyList/EmptyList";
import Comment from "components/Comment/Comment";
import { getUserReplies } from "../api/comment";
// import { useRefetchOnFocus } from "hooks/useRefetchOnFocus";
import { IComment } from "types/index";
import Colors from "constants/Colors";
import SCREENS from "constants/Screen";
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";

interface RepliesProps {
  navigation: NavigationProp<ParamListBase>;
}

const RepliesScreen = ({ navigation }: RepliesProps) => {
  const [refreshing, setRefreshing] = useState(false);
  const { colorMode } = useColorMode();
  const colorScheme = colorMode || "light";

  const {
    isPending,
    data,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    refetch,
    error,
  } = useInfiniteQuery({
    queryKey: ["replies"],
    queryFn: async ({ pageParam = 1 }) => {
      const { data } = await getUserReplies({ pageParam });
      return data;
    },
    getNextPageParam: (lastPage) => lastPage.nextCursor,
    getPreviousPageParam: (firstPage) => firstPage.prevCursor,
    initialPageParam: 1,
  });

  if (error) {
    Sentry.Native.captureException(error);
  }

  const handleRefresh = async () => {
    setRefreshing(true);
    await refetch();
    setRefreshing(false);
  };

  // useRefetchOnFocus(refetch);

  const loadMore = () => {
    if (hasNextPage) {
      fetchNextPage();
    }
  };

  const navigateToComments = (postId: string) => {
    navigation.navigate(SCREENS.POST_COMMENTS.name, { postId });
  };

  const renderComment = ({ item }: { item: IComment }) => {
    return (
      <TouchableOpacity onPress={() => navigateToComments(item.post)}>
        <Comment comment={item} />
      </TouchableOpacity>
    );
  };

  if (isPending)
    <Center flex={1} bg={Colors[colorScheme].background}>
      <Spinner />
    </Center>;

  return (
    <ErrorBoundary>
      <FlatList
        flex={1}
        data={data?.pages.flatMap((page) => page.results)}
        renderItem={renderComment}
        keyExtractor={(item, index) => item.id || index.toString()}
        ItemSeparatorComponent={Separator}
        ListEmptyComponent={<EmptyList text="No replies" />}
        onEndReachedThreshold={0.2}
        ListFooterComponent={isFetchingNextPage ? <Spinner /> : null}
        onEndReached={loadMore}
        refreshControl={
          <RefreshControl
            refreshing={refreshing}
            onRefresh={handleRefresh}
            tintColor={Colors[colorScheme].text}
          />
        }
      />
    </ErrorBoundary>
  );
};

export default RepliesScreen;
