// hooks/useComments.ts
import { useInfiniteQuery } from "@tanstack/react-query";
import { getPostComments } from "../../../api/comment";

export const useComments = (postId: string) => {
  const fetchComments = async ({ pageParam = 1 }) => {
    const { data } = await getPostComments({ postId, pageParam });
    return data;
  };

  return useInfiniteQuery({
    queryKey: ["comments", postId],
    queryFn: fetchComments,
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      if (lastPage.page + 1 > lastPage.totalPages) {
        return null;
      }

      return lastPage.page + 1;
    },
  });
};
