import { useMutation, useQueryClient } from "@tanstack/react-query";
import * as Sentry from "sentry-expo";
import Toast from "react-native-toast-message";
import * as Haptics from "expo-haptics";
import { AxiosError } from "axios";

import { createPostVote } from "../api/post";
import { IPost } from "types/index";

const useVoteMutation = (queryName: string) => {
  const queryClient = useQueryClient();

  const handleVote = async ({
    postId,
    vote,
  }: {
    postId: string;
    vote: -1 | 1;
  }) => {
    Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
    await analytics().logEvent("post_vote", {
      postId,
      vote,
    });
    const { data } = await createPostVote(postId, vote);
    return data;
  };

  const mutation = useMutation({
    mutationFn: handleVote,
    onMutate: async ({ postId, vote }: { postId: string; vote: -1 | 1 }) => {
      const newVote = vote;

      await queryClient.cancelQueries({ queryKey: [queryName] });

      // Backup of the previous state
      const previousPostsList = queryClient.getQueryData([queryName]);

      queryClient.setQueryData([queryName], (oldQueryData: any) => {
        const newPages = oldQueryData.pages.map((page: any) => {
          // Update only the page that contains the post we're interested in
          if (page.results.some((post: IPost) => post.id === postId)) {
            return {
              ...page,
              results: page.results.map((post: IPost) => {
                if (post.id === postId) {
                  let updatedPost = { ...post };
                  // Check if the user is undoing their vote
                  if (post.userVote === newVote) {
                    updatedPost = {
                      ...updatedPost,
                      userVote: undefined, // Reset userVote since they're undoing their action
                      upVotes: newVote === 1 ? post.upVotes - 1 : post.upVotes,
                      downVotes:
                        newVote === -1 ? post.downVotes - 1 : post.downVotes,
                    };
                  } else {
                    // Handle the case of changing vote or voting for the first time
                    updatedPost = {
                      ...updatedPost,
                      userVote: newVote,
                      upVotes:
                        newVote === 1
                          ? post.upVotes + 1
                          : post.userVote === -1
                          ? post.upVotes - 1
                          : post.upVotes,
                      downVotes:
                        newVote === -1
                          ? post.downVotes + 1
                          : post.userVote === 1
                          ? post.downVotes - 1
                          : post.downVotes,
                    };
                  }
                  return updatedPost;
                } else {
                  return post;
                }
              }),
            };
          } else {
            return page; // Return unmodified pages
          }
        });

        return { ...oldQueryData, pages: newPages };
      });

      return { previousPostsList };
    },
    onError: (error: AxiosError, newVote, context) => {
      queryClient.setQueryData([queryName], context?.previousPostsList);
      Sentry.Native.captureException(error);
      Toast.show({
        type: "info",
        text1: "Error",
        text2: error.response?.data.message || "Something went wrong",
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [queryName] });
    },
  });

  return mutation;
};

export default useVoteMutation;
