import { auth } from "../config/firebase";
import request from "../utils/request";

export const getCurrentUser = async () => {
  return request({
    method: "get",
    url: "/users/me",
  });
};

export const updateUser = async (
  values: Partial<{
    displayName: string;
    avatarChar: string;
    expoPushToken: string;
  }>
) => {
  return request({
    method: "patch",
    url: "/users/me",
    data: values,
  });
};

export const deleteUser = async () => {
  await request({
    method: "delete",
    url: "/users/me",
  });
  await auth.signOut();
};

export const blockUser = async (userId: string) => {
  return request({
    method: "post",
    url: `users/${userId}/block`,
  });
};
