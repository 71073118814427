import React, { ReactNode } from "react";
import { Video, ResizeMode } from "expo-av";
import {
  TouchableOpacity,
  TouchableWithoutFeedback,
  StyleSheet,
} from "react-native";
import {
  Box,
  Text,
  Row,
  Image,
  Column,
  useColorMode,
  Heading,
} from "native-base";
import relativeTime from "dayjs/plugin/relativeTime";
import ParsedText from "react-native-parsed-text";
import dayjs from "dayjs";
dayjs.extend(relativeTime);

import SCREENS from "constants/Screen";
import * as RootNavigation from "../../navigation/RootNavigation";
import { IMedia, IPost } from "types/index";
import Colors from "constants/Colors";

interface PostContentProps {
  data: IPost;
  actions?: ReactNode;
  onPress: () => void;
  truncate?: boolean;
  disabled?: boolean;
}

const PostContent = ({
  data,
  actions,
  onPress,
  truncate,
  disabled = false,
}: PostContentProps) => {
  const { colorMode } = useColorMode();
  const colorScheme = colorMode || "light";

  const { userSnapshot, text, media, deleted, communitySnapshot } = data;
  const video = React.useRef(null);

  const renderMediaContent = (media: IMedia[]) => {
    const images = media.filter((m) => m.type === "image");
    const videos = media.filter((m) => m.type === "video");

    const renderImages = () => {
      const getImage = (
        uri: string,
        flexValue: number,
        height: string | number
      ) => (
        <TouchableWithoutFeedback
          key={uri}
          onPress={async () => {
            RootNavigation.navigate(SCREENS.MEDIA_GALLERY.name, {
              images: media,
              selectedIndex: media.findIndex((m) => m.url === uri),
            });
          }}
          testID="post-image"
        >
          <Image
            source={{ uri }}
            flex={flexValue}
            h={height}
            borderRadius={10}
            alt="Post Image"
            mr={2}
          />
        </TouchableWithoutFeedback>
      );

      switch (images.length) {
        case 1:
          return getImage(images[0].url, 1, 300);
        case 2:
          return images.map((img) => getImage(img.url, 0.5, 150));
        case 3:
          return [
            <Box flex={0.5} key="left-side">
              {getImage(images[0].url, 1, 300)}
            </Box>,
            <Column flex={0.5} key="right-side" space={2}>
              {getImage(images[1].url, 1, "50%")}
              {getImage(images[2].url, 1, "50%")}
            </Column>,
          ];
        case 4:
          return (
            <Column flex={1} space={2}>
              <Row>
                {getImage(images[0].url, 0.5, 150)}
                {getImage(images[1].url, 0.5, 150)}
              </Row>
              <Row>
                {getImage(images[2].url, 0.5, 150)}
                {getImage(images[3].url, 0.5, 150)}
              </Row>
            </Column>
          );

        default:
          return null;
      }
    };

    const renderVideos = () =>
      videos.map((videoMedia) => (
        <Box flex={1} key={videoMedia.url}>
          <Video
            testID="post-video"
            ref={video}
            style={{ width: "100%", height: 300 }}
            source={{ uri: videoMedia.url }}
            useNativeControls
            resizeMode={ResizeMode.CONTAIN}
          />
        </Box>
      ));

    return (
      <>
        {images.length > 0 && renderImages()}
        {videos.length > 0 && renderVideos()}
      </>
    );
  };

  const onUrlPress = (uri: string) => {
    RootNavigation.navigate(SCREENS.WEB_VIEW.name, {
      uri,
    });
  };

  if (deleted) {
    return (
      <Box p={4}>
        <Text>Post deleted</Text>
      </Box>
    );
  }

  return (
    <Column space={2} py={2}>
      <TouchableOpacity
        disabled={!communitySnapshot?._id}
        onPress={() =>
          RootNavigation.navigate(SCREENS.COMMUNITY.name, {
            communityId: communitySnapshot?._id,
          })
        }
      >
        <Row justifyContent="space-between" alignItems="center">
          <Row space={2} alignItems="center">
            {communitySnapshot?.profileImage ? (
              <Image
                source={{ uri: communitySnapshot.profileImage }}
                alt={communitySnapshot.name}
                h={6}
                w={6}
                rounded="full"
              />
            ) : (
              <Box
                h={7}
                w={7}
                rounded="full"
                alignItems="center"
                justifyContent="center"
                borderWidth={1}
                borderColor={Colors.light.secondary_text}
              >
                <Text fontSize="xs">{userSnapshot.avatarChar}</Text>
              </Box>
            )}
            <Text
              fontSize="xs"
              _dark={{
                color: "trueGray.300",
              }}
            >
              {communitySnapshot?.name}
              {" • "}
              <Text
                color="trueGray.500"
                fontSize="xs"
                _dark={{
                  color: "trueGray.300",
                }}
              >{`by ${userSnapshot.displayName} ${dayjs(
                data.createdAt
              ).fromNow()}`}</Text>
            </Text>
          </Row>
        </Row>
      </TouchableOpacity>

      <TouchableOpacity onPress={onPress} disabled={disabled || !data.id}>
        {Boolean(data.title) && (
          <Heading size="sm" mb={1}>
            {data.title}
          </Heading>
        )}
        <ParsedText
          numberOfLines={truncate ? 3 : undefined}
          style={[styles.text, { color: Colors[colorScheme].text }]}
          parse={[
            {
              type: "url",
              style: [styles.url, { color: Colors[colorScheme].danger }],
              onPress: onUrlPress,
            },
            {
              pattern: /#(\w+)/,
              style: [
                styles.hashTag,
                {
                  color: Colors[colorScheme].danger,
                },
              ],
            },
          ]}
          childrenProps={{ allowFontScaling: false }}
        >
          {text}
        </ParsedText>
      </TouchableOpacity>

      {media && media?.length > 0 ? (
        <Box flexDirection="row" flexWrap="wrap" my={2}>
          {renderMediaContent(media)}
        </Box>
      ) : null}
      <Row alignItems="center">{actions}</Row>
    </Column>
  );
};

export default PostContent;

const styles = StyleSheet.create({
  text: {
    fontFamily: "airbnb-cereal-book",
    letterSpacing: 0.5,
    lineHeight: 20,
    fontSize: 15,
  },
  hashTag: {
    color: "#00f",
  },
  url: {
    textDecorationLine: "underline",
  },
});
