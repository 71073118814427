import React, { useState } from "react";
import { TouchableOpacity } from "react-native";
import { Ionicons } from "@expo/vector-icons";
import { Box, Text, Input, useColorMode, Row } from "native-base";

import Colors from "constants/Colors";

interface CreateCommentFormProps {
  submitComment: ({
    text,
    clearForm,
  }: {
    text: string;
    clearForm: () => void;
  }) => void;
}

const CreateCommentForm: React.FC<CreateCommentFormProps> = ({
  submitComment,
}) => {
  const [text, setText] = useState("");

  const wordCount = 500;

  const clearForm = () => {
    setText("");
  };

  const { colorMode } = useColorMode();
  const colorScheme = colorMode || "light";

  return (
    <Box px={4} bg={Colors[colorScheme].background}>
      <Text color={Colors.light.secondary_text}>
        {text.length}/{wordCount}
      </Text>
      <Row
        alignItems="center"
        p={2}
        space={2}
        rounded={10}
        style={{
          backgroundColor: Colors[colorScheme].inputBackground,
          borderColor: Colors[colorScheme].secondary_text,
        }}
      >
        <Box flex={1}>
          <Input
            bg={Colors[colorScheme].inputBackground}
            color={Colors[colorScheme].text}
            placeholder="Type in comment"
            placeholderTextColor={Colors.light.secondary_text}
            multiline
            maxLength={wordCount}
            onChangeText={setText}
            value={text}
          />
        </Box>
        <TouchableOpacity onPress={() => submitComment({ text, clearForm })}>
          <Box bg={Colors.light.secondary} px={4} py={2} rounded={10}>
            <Ionicons
              name="md-send"
              size={20}
              color={Colors.light.background}
            />
          </Box>
        </TouchableOpacity>
      </Row>
    </Box>
  );
};

export default CreateCommentForm;
