import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { Box, FlatList, Input, Spinner, useColorMode } from "native-base";

import { RefreshControl } from "react-native";
import { NavigationProp, ParamListBase } from "@react-navigation/native";

import Separator from "components/Separator/Separator";
import { searchCommunities } from "../../api/community";
import CommunityItem from "components/CommunityItem/CommunityItem";
import SCREENS from "constants/Screen";
import EmptyList from "components/EmptyList/EmptyList";
import useDebounce from "hooks/useDebounce";
import Colors from "constants/Colors";
import { ICommunity } from "types/index";
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";

interface SearchNotificationsProps {
  navigation: NavigationProp<ParamListBase>;
}

export const SEARCH_QUERY_KEY = "SearchCommunities";

const SearchCommunities = ({ navigation }: SearchNotificationsProps) => {
  const [refreshing, setRefreshing] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [query, setQuery] = useState("");

  const { colorMode } = useColorMode();
  const queryClient = useQueryClient();
  const debouncedSearchTerm = useDebounce(searchTerm, 500); // debounce by 500ms

  const colorScheme = colorMode || "light";

  useEffect(() => {
    if (debouncedSearchTerm) {
      setQuery(debouncedSearchTerm);
      queryClient.removeQueries({ queryKey: [SEARCH_QUERY_KEY] });
    }
  }, [debouncedSearchTerm]);

  const handleInputChange = (text: string) => {
    setSearchTerm(text);
  };

  const {
    data,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    refetch,
    isFetching,
  } = useInfiniteQuery({
    queryKey: [SEARCH_QUERY_KEY, query],
    queryFn: async ({ pageParam = 1 }) => {
      const { data } = await searchCommunities({ q: query, pageParam });

      return data;
    },
    getNextPageParam: (lastPage) => {
      if (lastPage.page + 1 > lastPage.totalPages) {
        return null;
      }

      return lastPage.page + 1;
    },
    initialPageParam: 1,
    enabled: !!query,
  });

  const renderCommunities = ({ item }: { item: ICommunity }) => {
    return (
      <CommunityItem
        community={item}
        onPress={() => {
          navigation.navigate(SCREENS.COMMUNITY.name, {
            communityId: item.id,
          });
        }}
      />
    );
  };

  const handleRefresh = async () => {
    setRefreshing(true);
    await refetch();
    setRefreshing(false);
  };

  const loadMore = () => {
    if (hasNextPage) {
      fetchNextPage();
    }
  };

  return (
    <ErrorBoundary>
      <Box flex={1} bg={Colors[colorScheme].background}>
        <Input
          m={2}
          onChangeText={handleInputChange}
          value={searchTerm}
          placeholder="Search..."
        />
        {isFetching ? (
          <Spinner />
        ) : (
          <FlatList
            px={2}
            data={data?.pages.flatMap((page) => page.results)}
            renderItem={renderCommunities}
            keyExtractor={(item, index) => item.id || index.toString()}
            ItemSeparatorComponent={Separator}
            onEndReachedThreshold={0.2}
            ListFooterComponent={isFetchingNextPage ? <Spinner /> : null}
            onEndReached={loadMore}
            ListEmptyComponent={
              <EmptyList
                text={query.length > 0 ? "No results found" : "Type to search"}
              />
            }
            refreshControl={
              <RefreshControl
                refreshing={refreshing}
                onRefresh={handleRefresh}
                tintColor={Colors[colorScheme].text}
              />
            }
          />
        )}
      </Box>
    </ErrorBoundary>
  );
};

export default SearchCommunities;
