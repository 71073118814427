import React from "react";
import { TouchableOpacity } from "react-native";
import { Box, Row, Text } from "native-base";

import Colors from "constants/Colors";
import * as RootNavigation from "../../navigation/RootNavigation";
import SCREENS from "constants/Screen";
import { useUserContext } from "../../context/UserContext";

const ProfileCard = () => {
  const { user } = useUserContext();

  return (
    <TouchableOpacity
      onPress={async () => {
        RootNavigation.navigate(SCREENS.USER_PROFILE.name, {});
      }}
    >
      <Row
        alignItems="center"
        px={4}
        _dark={{
          bg: Colors.dark.background,
        }}
        _light={{
          bg: Colors.light.background,
        }}
      >
        <Box
          h={10}
          w={10}
          rounded="full"
          borderWidth={1}
          borderColor={Colors.light.secondary_text}
          alignItems="center"
          justifyContent="center"
          mr={2}
        >
          <Text fontSize="md">{user?.avatarChar}</Text>
        </Box>
        <Box>
          <Text fontWeight="bold">{user?.displayName}</Text>
          <Text
            color="trueGray.500"
            fontSize="xs"
            _dark={{
              color: "trueGray.300",
            }}
          >
            View profile
          </Text>
        </Box>
      </Row>
    </TouchableOpacity>
  );
};

export default ProfileCard;
