import React, { useState } from "react";
import { RefreshControl } from "react-native";
import { useColorMode, FlatList, Spinner, Center } from "native-base";
import { useInfiniteQuery } from "@tanstack/react-query";
import { getCommunities } from "../../../api/community";
import { ICommunity, IResponse } from "types/index";
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";

import Colors from "constants/Colors";
import CommunityItem from "components/CommunityItem/CommunityItem";
import { NavigationProp, ParamListBase } from "@react-navigation/native";
import SCREENS from "constants/Screen";
import EmptyList from "components/EmptyList/EmptyList";
import { GeoLocation, fetchLocation } from "utils/location";
import { AxiosResponse } from "axios";

interface CountryCommunitiesProps {
  navigation: NavigationProp<ParamListBase>;
}

const CountryCommunities = ({ navigation }: CountryCommunitiesProps) => {
  const [geoLocation, setGeoLocation] = useState<GeoLocation>({
    latitude: null,
    longitude: null,
    city: null,
    countryCode: null,
  });
  const [refreshing, setRefreshing] = useState(false);
  const { colorMode } = useColorMode();
  const colorScheme = colorMode || "light";

  const getLocation = async () => {
    const location = await fetchLocation();
    if (location) {
      setGeoLocation({
        latitude: location.latitude,
        longitude: location.longitude,
        city: location.city,
        countryCode: location.countryCode,
      });
    } else {
      // Handle case where no location is found
      setGeoLocation({
        latitude: null,
        longitude: null,
        city: null,
        countryCode: null,
      });
    }
  };

  const {
    isPending,
    data,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    refetch,
  } = useInfiniteQuery({
    queryKey: ["communities", "country", geoLocation.countryCode],
    queryFn: async ({ pageParam = 1 }) => {
      await getLocation();
      const response: AxiosResponse<IResponse<ICommunity>> | void =
        await getCommunities({
          visibilityScope: "country",
          countryCode: geoLocation.countryCode ?? undefined,
          pageParam,
        });
      const { data } = response || {
        data: {
          results: [],
          totalPages: 0,
          page: 0,
        },
      };
      return data;
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      if (lastPage.page + 1 > lastPage.totalPages) {
        return null;
      }

      return lastPage.page + 1;
    },
  });

  const handleRefresh = async () => {
    setRefreshing(true);
    await refetch();
    setRefreshing(false);
  };

  const loadMore = () => {
    if (hasNextPage) {
      fetchNextPage();
    }
  };

  const renderCommunity = ({ item }: { item: ICommunity }) => {
    return (
      <CommunityItem
        community={item}
        onPress={() =>
          navigation.navigate(SCREENS.COMMUNITY.name, {
            communityId: item.id,
          })
        }
      />
    );
  };

  if (isPending)
    <Center
      flex={1}
      _dark={{
        bg: Colors.dark.background,
      }}
      _light={{
        bg: Colors.light.background,
      }}
    >
      <Spinner />
    </Center>;

  return (
    <ErrorBoundary>
      <FlatList
        p={4}
        data={data?.pages.flatMap((page) => page.results)}
        renderItem={renderCommunity}
        keyExtractor={(item, index) => item.id || index.toString()}
        ListEmptyComponent={
          <EmptyList text="No communities in your country yet. Feel free to create one or check back later 😊" />
        }
        onEndReachedThreshold={0.2}
        ListFooterComponent={isFetchingNextPage ? <Spinner /> : null}
        onEndReached={loadMore}
        refreshControl={
          <RefreshControl
            refreshing={refreshing}
            onRefresh={handleRefresh}
            tintColor={Colors[colorScheme].text}
          />
        }
      />
    </ErrorBoundary>
  );
};

export default CountryCommunities;
