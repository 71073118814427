import { MaterialCommunityIcons } from "@expo/vector-icons";
import { Row, Text } from "native-base";
import React from "react";
import { TouchableWithoutFeedback } from "react-native";
import Colors from "constants/Colors";

interface CommentCountProps {
  count: number;
  onPress?: () => void;
}

const CommentCount: React.FC<CommentCountProps> = ({ onPress, count }) => {
  return (
    <TouchableWithoutFeedback onPress={onPress}>
      <Row alignItems="center">
        <MaterialCommunityIcons
          name="comment-outline"
          size={16}
          color={Colors.light.secondary_text}
        />
        <Text mx={2} color={Colors.light.secondary_text} fontWeight="500">
          {count ? count : "Comment"}
        </Text>
      </Row>
    </TouchableWithoutFeedback>
  );
};

export default CommentCount;
