import { useState, useEffect } from "react";
import { useColorMode } from "native-base";
import { StatusBarStyle } from "react-native";

const useThemeManager = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  const [currentStatusBarStyle, setStatusBarStyle] = useState<
    StatusBarStyle | null | undefined
  >(colorMode === "dark" ? "light-content" : "dark-content");

  useEffect(() => {
    const newStyle = colorMode === "dark" ? "light-content" : "dark-content";
    setStatusBarStyle(newStyle);
  }, [colorMode]);

  const handleToggleColorMode = async () => {
    toggleColorMode();
  };

  return {
    currentStatusBarStyle,
    handleToggleColorMode,
    colorMode,
  };
};

export default useThemeManager;
