import {
  Box,
  Column,
  Heading,
  Image,
  Row,
  Text,
  Button,
  Icon,
  IconButton,
} from "native-base";
import { Entypo } from "@expo/vector-icons";
import Colors from "constants/Colors";
import { ICommunity } from "types/index";
import { TouchableOpacity } from "react-native";

interface CommunityHeaderProps {
  communityDetailData: ICommunity;
  isModeratorStatus?: boolean;
  isMemberStatus?: boolean;
  handleJoin: () => void;
  handleLeave: () => void;
  navigateToEditCommunity: () => void;
  onMemberCountPress: () => void;
  onPressShare: () => void;
}

const CommunityHeader = ({
  communityDetailData,
  isModeratorStatus,
  isMemberStatus,
  handleJoin,
  handleLeave,
  navigateToEditCommunity,
  onMemberCountPress,
  onPressShare,
}: CommunityHeaderProps) => {
  // Define a list of emojis to display based on the members count
  const emojis = ["😄", "🥸", "🧑‍🎄", "😎"];

  // Generate emoji elements based on the members count
  const generateEmojis = (count: number) => {
    // Limit the number of emojis to 4
    const emojiCount = Math.min(count, 4);
    return Array.from({ length: emojiCount }, (_, index) => (
      <Box
        key={index}
        h={8}
        w={8}
        rounded="full"
        alignItems="center"
        justifyContent="center"
        borderWidth={2}
        borderColor="gray.400"
        bg="white"
        ml={index > 0 ? -3 : 0}
        _dark={{
          bg: Colors.dark.background,
        }}
      >
        {emojis[index]}
      </Box>
    ));
  };

  return (
    <Box
      borderBottomWidth={2}
      py={1}
      _light={{
        borderBottomColor: Colors.light.background_secondary,
      }}
    >
      <Image
        source={{ uri: communityDetailData.profileImage }}
        alt={communityDetailData.name}
        h={140}
      />

      <Row justifyContent="space-between" px={4} py={2} space={2}>
        <Column space={1} flexShrink={1}>
          <Heading
            size="lg"
            color="gray.800"
            _dark={{
              color: "gray.100",
            }}
          >
            {communityDetailData.name}
          </Heading>
          <Text
            color="gray.600"
            _dark={{
              color: "gray.400",
            }}
          >
            {communityDetailData.description}
          </Text>

          <TouchableOpacity onPress={onMemberCountPress}>
            <Row alignItems="center" space={2}>
              <Text>
                <Text bold>{communityDetailData.membersCount}</Text>
                <Text color="gray.500">
                  {communityDetailData.membersCount > 1
                    ? " members"
                    : " member"}
                </Text>
              </Text>
              <Row space={-2}>
                {generateEmojis(communityDetailData.membersCount)}
              </Row>
            </Row>
          </TouchableOpacity>
          {isModeratorStatus && (
            <Button
              size="sm"
              variant="subtle"
              onPress={navigateToEditCommunity}
              alignSelf="flex-start"
            >
              Edit
            </Button>
          )}
        </Column>

        <Row>
          <IconButton
            onPress={onPressShare}
            icon={
              <Icon
                as={Entypo}
                name="share"
                _dark={{
                  color: Colors.dark.text,
                }}
                _light={{
                  color: Colors.light.text,
                }}
              />
            }
            borderRadius="full"
            alignSelf="center"
            mr={2}
          />
          {isMemberStatus ? (
            <Button
              variant="outline"
              onPress={() => {
                handleLeave();
              }}
              alignSelf="center"
              size="sm"
            >
              Leave
            </Button>
          ) : (
            <Button
              variant="solid"
              onPress={() => {
                handleJoin();
              }}
              alignSelf="center"
              size="sm"
            >
              Join
            </Button>
          )}
        </Row>
      </Row>
    </Box>
  );
};

export default CommunityHeader;
