import React from "react";
import { Box, Text } from "native-base";
interface EmptyListProps {
  text?: string;
}

const EmptyList: React.FC<EmptyListProps> = ({ text }) => {
  return (
    <Box p={4}>
      <Text
        color="gray.600"
        _dark={{
          color: "gray.100",
        }}
      >
        {text || "Nothing to show yet"}
      </Text>
    </Box>
  );
};

export default EmptyList;
