import { useInfiniteQuery } from "@tanstack/react-query";
import { getUserNotifications } from "../../api/notification";
import { useState } from "react";
import {
  Box,
  Center,
  FlatList,
  Heading,
  Spinner,
  Text,
  useColorMode,
} from "native-base";
import { NavigationProp, ParamListBase } from "@react-navigation/native";
import { RefreshControl, TouchableOpacity } from "react-native";
import dayjs from "dayjs";
import EmptyList from "components/EmptyList/EmptyList";
import Separator from "components/Separator/Separator";
import Colors from "constants/Colors";
import { INotification } from "types/index";
import SCREENS from "constants/Screen";
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";

interface NotificationsProps {
  navigation: NavigationProp<ParamListBase>;
}

const Notifications = ({ navigation }: NotificationsProps) => {
  const [refreshing, setRefreshing] = useState(false);
  const { colorMode } = useColorMode();
  const colorScheme = colorMode || "light";

  const {
    isPending,
    data,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    refetch,
  } = useInfiniteQuery({
    queryKey: ["notifications"],
    queryFn: async ({ pageParam = 1 }) => {
      const { data } = await getUserNotifications({ pageParam });
      return data;
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      if (lastPage.page + 1 > lastPage.totalPages) {
        return null;
      }

      return lastPage.page + 1;
    },
  });

  const onNotificationPress = (item: INotification) => {
    switch (item.type) {
      case "COMMENT_REPLY":
      case "TRENDING_TOPIC":
        navigation.navigate(SCREENS.POST_COMMENTS.name, {
          postId: item.relatedPost,
        });
        break;
      case "MODERATION_ALERT":
      case "UPVOTE_MILESTONE":
      case "NEW_POST":
        navigation.navigate(SCREENS.COMMUNITY.name, {
          communityId: item.relatedCommunity,
        });
        break;
      default:
        break;
    }
  };

  const renderNotifications = ({ item }: { item: INotification }) => {
    return (
      <TouchableOpacity onPress={() => onNotificationPress(item)}>
        <Box p={4}>
          <Text>
            {item.message} {dayjs(item.createdAt).fromNow()}
          </Text>
        </Box>
      </TouchableOpacity>
    );
  };

  const handleRefresh = async () => {
    setRefreshing(true);
    await refetch();
    setRefreshing(false);
  };

  const loadMore = () => {
    if (hasNextPage) {
      fetchNextPage();
    }
  };

  return (
    <ErrorBoundary>
      <Box flex={1} background={Colors[colorScheme].background} safeAreaTop>
        {isPending ? (
          <Center flex={1}>
            <Spinner />
          </Center>
        ) : (
          <FlatList
            data={data?.pages.flatMap((page) => page.results)}
            renderItem={renderNotifications}
            keyExtractor={(item, index) => item.id || index.toString()}
            ItemSeparatorComponent={Separator}
            ListEmptyComponent={() => (
              <EmptyList text="Nothing to see here yet—enjoy the calm..." />
            )}
            ListHeaderComponent={
              <Heading size="lg" my={4} mx={4}>
                Notifications 🔔
              </Heading>
            }
            onEndReachedThreshold={0.2}
            ListFooterComponent={isFetchingNextPage ? <Spinner /> : null}
            onEndReached={loadMore}
            refreshControl={
              <RefreshControl
                refreshing={refreshing}
                onRefresh={handleRefresh}
                tintColor={Colors[colorScheme].text}
              />
            }
          />
        )}
      </Box>
    </ErrorBoundary>
  );
};

export default Notifications;
