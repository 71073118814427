import { useState } from "react";
import { RefreshControl } from "react-native";
import { Center, FlatList, Spinner, useColorMode } from "native-base";
import { useInfiniteQuery } from "@tanstack/react-query";
import EmptyList from "components/EmptyList/EmptyList";
import Separator from "components/Separator/Separator";
import { getCommunityMembers } from "../../api/community";
import Colors from "constants/Colors";
import { ICommunityMember } from "types/index";
import MemberCard from "./MemberCard/MemberCard";
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";

const CommunityMembers = ({
  route,
}: {
  route: {
    params: {
      communityId: string;
    };
  };
}) => {
  const [refreshing, setRefreshing] = useState(false);
  const { communityId } = route.params;

  const { colorMode } = useColorMode();
  const colorScheme = colorMode || "light";

  const {
    isPending,
    data,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    refetch,
  } = useInfiniteQuery({
    queryKey: ["communityMembers", communityId],
    queryFn: async ({ pageParam = 1 }) => {
      const { data } = await getCommunityMembers({ pageParam, communityId });
      return data;
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      if (lastPage.page + 1 > lastPage.totalPages) {
        return null;
      }

      return lastPage.page + 1;
    },
  });

  const loadMore = () => {
    if (hasNextPage) {
      fetchNextPage();
    }
  };

  const renderItem = ({ item }: { item: ICommunityMember }) => {
    return <MemberCard member={item} />;
  };

  if (isPending)
    return (
      <Center
        flex={1}
        _dark={{
          bg: Colors.dark.background,
        }}
        _light={{
          bg: Colors.light.background,
        }}
      >
        <Spinner />
      </Center>
    );

  const handleRefresh = async () => {
    setRefreshing(true);
    await refetch();
    setRefreshing(false);
  };

  return (
    <ErrorBoundary>
      <FlatList
        flex={1}
        data={data?.pages.flatMap((page) => page.results)}
        renderItem={renderItem}
        keyExtractor={(item) => item.id}
        ItemSeparatorComponent={Separator}
        ListEmptyComponent={EmptyList}
        onEndReachedThreshold={0.2}
        ListFooterComponent={isFetchingNextPage ? <Spinner /> : null}
        onEndReached={loadMore}
        refreshControl={
          <RefreshControl
            refreshing={refreshing}
            onRefresh={handleRefresh}
            tintColor={Colors[colorScheme].text}
          />
        }
      />
    </ErrorBoundary>
  );
};

export default CommunityMembers;
