import request from "../utils/request";

export const postComment = async ({
  text,
  postId,
}: {
  text: string;
  postId: string;
}) => {
  return request({
    method: "post",
    url: `/posts/${postId}/comment`,
    data: { text },
  });
};

export const getPostComments = async ({
  postId,
  pageParam,
}: {
  postId: string;
  pageParam: number;
}) => {
  return request({
    method: "get",
    url: `/posts/${postId}/comment`,
    params: {
      populate: "user",
      sortBy: "createdAt:asc",
      limit: 30,
      page: pageParam,
    },
  });
};

export const getUserReplies = async ({ pageParam }: { pageParam: number }) => {
  return request({
    method: "get",
    url: `/users/replies`,
    params: {
      page: pageParam,
    },
  });
};

export const deleteComment = async (commentId: string) => {
  return request({
    method: "delete",
    url: `/posts/${commentId}`,
  });
};
