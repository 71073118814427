import { Ionicons } from "@expo/vector-icons";
import * as Font from "expo-font";
import * as SplashScreen from "expo-splash-screen";
import * as React from "react";
import * as Sentry from "sentry-expo";

export default function useCachedResources() {
  const [isLoadingComplete, setLoadingComplete] = React.useState(false);

  // Load any resources or data that we need prior to rendering the app
  React.useEffect(() => {
    async function loadResourcesAndDataAsync() {
      try {
        SplashScreen.preventAutoHideAsync();

        // Load fonts
        await Font.loadAsync({
          ...Ionicons.font,
          "airbnb-cereal-medium": require("../assets/fonts/AirbnbCerealMedium.ttf"),
          "airbnb-cereal-book": require("../assets/fonts/AirbnbCerealBook.ttf"),
          "airbnb-cereal-bold": require("../assets/fonts/AirbnbCerealBold.ttf"),
        });
      } catch (e) {
        // We might want to provide this error information to an error reporting service
        Sentry.Native.captureException(e);
      } finally {
        setLoadingComplete(true);
        SplashScreen.hideAsync();
      }
    }

    loadResourcesAndDataAsync();
  }, []);

  return isLoadingComplete;
}
